@import '../../assets/styles/variables';

.wrapper {
  padding-top: 60px;
  padding-bottom: 100px;
  min-height: 100vh;
}
.message {
  margin-bottom: 40px;
}
.title {
  margin-bottom: 20px;
}
