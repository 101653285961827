@import '../../assets/styles/variables';

.dashboard-module {
  padding: 50px 0;

  &__notifications-block {
    &__preloader {
      width: 100%;
      height: auto;
    }
  }

  &__header {
    &-actions {
    }

    &-prepay,
    &-funds {
      min-width: 144px;
      max-width: 144px;
    }
    &-prepay {
      margin-right: 12px;
    }
    &-funds {
      background: $primaryYellow;
    }
  }

  &__title {
    margin-top: 30px;
  }
  &__notifications {
    margin-top: 20px;
  }
  &__avatar .avatar {
    border: 2px solid #fff;
  }

  &__summary {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 20px;
    margin-top: 30px;
  }

  &__fundraising {
    &-start {
      display: block;
      max-width: 234px;
      background: $primaryYellow;
    }
    &-title {
      margin: 90px 0 30px;
    }
    &-result {
      margin-top: 20px;
    }
  }
}
