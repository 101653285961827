.uploaded {
  display: inline-block;
  max-width: 250px;
  background: #000;
  position: relative;
  border-radius: 70px;
  padding: 8px 40px 8px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
}

.remove {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}