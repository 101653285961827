@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.textarea-field {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  border: none;
  outline: none;
  padding: 5px 0 25px;
  border-bottom: 1px solid #d8d6da;
  resize: none;
  @include hideScroll;
  &:disabled {
    background: transparent;
    padding: 7px 0;
  }
  &__wrap {
    padding-top: 7px;
  }
  &__group {
    box-sizing: border-box;
    position: relative;
  }
  &:focus {
    border-bottom: 2px solid #000;
  }
  &__label {
    font-size: 16px;
    line-height: 24px;
    color: #9e98a3;
    position: absolute;
    top: 5px;
    left: 0;

    pointer-events: none;
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  &:focus ~ &__label,
  &__filled ~ &__label,
  &:not(:placeholder-shown) ~ &__label {
    font-size: 12px;
    line-height: 16px;
    color: #9e98a3;
    top: -10px;
    left: 0;
  }

  &:-webkit-autofill ~ &__label {
    font-size: 12px;
    line-height: 16px;
    color: #9e98a3;
    top: -10px;
    left: 0;
  }
  &__limit {
    position: absolute;
    bottom: 9px;
    right: 9px;
    color: #9e98a3;
    &-start {
      &-error {
        color: $errorRed;
      }
    }
  }

  &__error,
  &__error:focus {
    border-color: $errorRed;
  }
  &__error-text {
    color: $errorRed;
  }

  &__wrap.read-only .textarea-field {
    border-bottom: none;
    &:disabled {
      color: $primaryBlack;
    }
  }
}
