@import '../../../assets/styles/variables';

.wrapper {
  margin: 50px 0;
}

.content {
  margin-top: 20px;
}

.title {}

.empty {
  color: $primaryGrey;
}

.more {}

