@import '../../../assets/styles/variables';

$maxWidth: 550px;

.fundraising-goal-form {
  &__notifications,
  &__payment {
    margin-top: 15px;
  }
  &__subtitle {
    margin-top: 50px;
  }
  &__goal {
    max-width: $maxWidth;
    margin-top: 5px;
    &-desc {
      width: $maxWidth;
      margin-top: 20px;
      color: $primaryBlack;
      opacity: 0.4;
    }
  }
  &__checkboxes {
    margin-top: 60px;
    & .checkbox:not(:first-child) {
      margin-top: 20px;
    }
    & .checkbox span {
      color: $primaryGrey;
    }
    & .checkbox span a {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
    & .checkbox span.error {
      color: $errorRed;
    }
  }
  &__disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 70px;
  }
  &__submit {
    max-width: 550px;
  }
  &__reset {
    margin-right: 20px;
    min-width: 250px;
  }
}
