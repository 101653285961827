@import '../../../assets/styles/variables';

.withdraw_history {
  &__list {
    margin-top: 20px;
    &_item {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
  &__cancel {
    text-decoration: underline;
    color: $primaryGrey;
    text-underline-offset: 2px;
  }
  &__more {
    margin-top: 40px;
    width: 100%;
  }
}
