.cash-detail-box {
  min-width: 288px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    &-title {
      color: #9e98a3;
      font-size: 16px;
      line-height: 24px;
    }
    &-value {
      font-size: 26px;
      line-height: 32px;
      color: #000;
    }
  }
}
