@import '../../../../assets/styles/variables';

.page {
  padding: 50px 0;
  & section {
    margin-top: 40px;
  }
  &__pricing {
    &_desc {
      margin-top: 15px;
    }
    &_groups {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-column-gap: 20px;
      margin-top: 15px;
    }
    &_group {
      padding-left: 45px;
      padding-top: 18px;
      padding-bottom: 18px;
      position: relative;
      &::after {
        content: '';
        background: $primaryYellow;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
    }
    &_list {
      margin-top: 30px;
      & li {
        padding-left: 20px;
        position: relative;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          background: $primaryYellow;
          border-radius: 50%;
        }
      }
    }
  }

  &__risk {
    &_list {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 24px;
      margin: 24px 0;
      i {
        width: 40px;
        min-width: 40px;
        height: 40px;
        background: $primaryYellow;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 22px;
        font-style: unset;
      }
      li {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  &__repayments {
    &_desc {
      margin-top: 15px;
    }
    & h3 {
      margin-top: 45px;
    }
    & img {
      max-width: 100%;
    }
    &_image {
      display: block;
      margin: 50px auto 0;
    }
    &_dropdown {
      margin-top: 40px;
    }
    & .page__summary {
      margin-top: 18px;
    }
    &_option {
      margin-top: 35px;
    }
  }

  &__summary {
    display: flex;
    gap: 64px;
  }

  &__grouped_list {
    margin-top: 35px;
    & li {
      padding-left: 30px;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 16px;
        height: 16px;
        background: $primaryYellow;
        border-radius: 50%;
      }
    }
  }

  &__wrong {
    background: #f4f4f5;
    border-radius: 8px;
    padding: 35px;
  }

  &__agreements {
    & a {
      text-decoration: underline;
    }
    &_desc {
      margin-top: 15px;
    }
    &_list {
      margin-top: 20px;
      & li {
        padding-left: 20px;
        position: relative;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          background: $primaryYellow;
          border-radius: 50%;
        }
      }
    }
  }
}

.links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.link {
  margin-top: 25px;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 32px;
  color: #9E98A3;
  padding: 8px 0;
  &_selected {
    color: #000;
    border-bottom: 4px solid #FFD200;
  }
  &:not(:last-child) {
    margin-right: 30px;
  }
}
