.scr_select {
  &__control {
    border-radius: 0!important;
    border: unset!important;
    border-bottom: 1px solid #D8D6DA!important;
    margin-top: 10px;
    &--is-focused,
    &--menu-is-open {
      border-color: #D8D6DA!important;
      box-shadow: unset!important;
    }
  }

  &__value-container {
    padding: 2px 8px 2px 0!important;
  }

  &__indicator-separator {
    display: none!important;
  }
}