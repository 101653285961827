@import "../../../assets/styles/variables";

$gridTemplateColumn:  repeat(3, minmax(0, 1fr));
$gridGap: 30px;

.wrapper {}

.empty {
  margin-top: 40px;
  color: #333333;
}

.table {
  margin-top: 10px;

  &__wrapper {
    margin: 0 -20px;
  }

  &__inner {
    padding: 0 20px;
    width: 100%;
    overflow: auto;
  }

  &__thead {}
  &__thead_col {}

  &__thead,
  &__tbody_row {
    display: grid;
    grid-template-columns: $gridTemplateColumn;
    grid-column-gap: $gridGap;
    align-items: center;
    justify-content: space-between;
  }

  &__thead {
    border-bottom: 1px solid #E6E5E7;
    &_col {
      padding: 20px 0;
      font-size: 14px;
      line-height: 20px;
      color: #9E98A3;
    }
  }

  &__tbody {
    &_row { border-bottom: 1px solid #E6E5E7; }
    &_col { padding: 23px 0;  } // overflow: hidden
  }
  &__empty {
    margin-top: 25px;
    color: #9E98A3;
  }
}

.status {
  display: flex;
  align-items: center;
  gap: 10px;

  & :global .approved {
    color: $primaryBlack;
  }

  & :global .rejected {
    color: #E03352;
  }

  & :global .pending {
    color: $primaryGrey;
  }

  & :global .tooltip__content.bottom.center :global .tooltip__content-triangle {
    right: 45%;
  }

  &_tooltip {
    max-width: 300px;
  }
}

.controls {
  display: flex;
  align-items: center;
  gap: 30px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  gap: 6px;
  &_edit {
    color: $primaryGrey;
  }
  &_hidden {
    opacity: 0;
    pointer-events: none;
  }
  &_remove {
    color: #E03352;
  }
}

.button_add {
  margin-top: 40px;
  & :global .round-link {
    padding: 5px 22px;
  }
}

@media screen and (max-width: 700px) {
  .controls {
    gap: 10px;
  }
  .button {
    & span {
      display: none;
    }
  }

  .table {
    &__thead,
    &__tbody_row {
      grid-template-columns: 41% 30% 1fr;
      grid-column-gap: 10px;
    }
  }

  .button_add {
     width: 100%;
  }
}