@import '../../../assets/styles/variables';

.register-form {
  &__title {
    color: #000000;
    margin: 10px 0 0 0;
  }
  &__social {
    margin-top: 24px;
  }

  &__captcha {
    margin-top: 40px;
  }

  &__email,
  &__password,
  &__submit {
    margin-top: 32px;
  }

  &__password {
    margin-top: 30px;
  }
  &__terms {
    margin-top: 24px;
    & .checkbox {
      padding-left: 34px;
      & span.error {
        color: $errorRed;
      }
    }
    & .checkbox__checkmark {
      width: 20px;
      height: 20px;
      top: 4px;
      &::after {
        left: 10px;
        top: -2px;
        width: 7px;
        height: 17px;
      }
    }
    & label {
      & a {
        text-decoration: underline;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__bottom {
    margin-top: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    & span:first-child {
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
}
