@import '../../../assets/styles/variables';

.founder-detail-card {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &-info {
      flex-grow: 1;
      overflow: hidden;
    }
    &-avatar {
      margin-right: 50px;
    }
    &-top {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      &-inner {
        margin-right: 20px;
        overflow: hidden;
      }
    }
    &-goal div:first-child,
    &-income div:first-child,
    &-loan div:first-child {
      color: $primaryGrey;
    }
    &-socials {
      display: flex;
      justify-content: flex-start;
      margin-top: 15px;
      & a:not(:last-child) {
        margin-right: 7px;
      }
    }
    &-income {
      margin-top: 10px;
    }
    &-fundraising {
      margin-top: 12px;
      display: flex;
      justify-content: flex-start;
    }
  }

  &__personal {
    &-wrap {
      margin-top: 55px;
    }
    border-top: 1px solid #9e98a325;
    padding: 37px 0;
    margin-top: 20px;
    &-about {
      &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 -12px;
        & div {
          color: #000000;
          opacity: 0.7;
          margin: 8px 12px;
        }
      }
    }
    &-empty {
      color: $darkGrey;
      margin-top: -20px;
    }
    &-summary {
      margin-top: 20px;
      & h3:not(:first-child) {
        margin-top: 20px;
      }
      &-text {
        color: #000000;
        opacity: 0.7;
        margin-top: 5px;
      }
    }
  }

  &__career,
  &__education {
    margin-top: 28px;
  }

  &__career,
  &__education {
    &-list {
      margin-top: 10px;
      & li {
        color: #000000;
        opacity: 0.7;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & div:first-child {
          min-width: 180px;
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }
  &__education-list li div:first-child {
    min-width: 140px;
  }

  &__document {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 28px;
    & svg {
      margin-right: 10px;
    }
  }

  &__recommendations {
    &-wrap {
      margin-top: 23px;
    }
    border-top: 1px solid #e6e5e7;
    padding: 31px 0;
    margin-top: 20px;
  }

  &__review {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    & h6 {
      margin-top: 5px;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &-content {
      flex-grow: 1;
    }
    &-comment {
      margin-top: 8px;
    }
    &-avatar {
      margin-right: 20px;
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-socials {
      display: flex;
      align-items: center;
      margin-left: 12px;
      & svg,
      & a {
        width: 20px;
        height: 20px;
      }
      & a:not(:last-child) {
        margin-right: 4px;
      }
    }
    &-comment {
      color: #000000;
      opacity: 0.7;
      font-style: italic;
    }
  }

  &__startup {
    border: 2px solid #e6e5e7;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 67px 118px 80px;
    margin-top: 20px;
    &-empty {
      color: $darkGrey;
      margin-top: 10px;
    }
    &-logo {
      display: block;
      margin-top: -27px !important;
      & img {
        width: 100%;
        height: 100%;
        max-height: 70px;
        object-fit: contain;
      }
    }
    &-wrap {
      margin-top: 48px;
    }
    &-header {
      text-align: center;
      & a {
        margin-top: 4px;
        display: inline-block;
      }
    }
    &-position {
      margin-top: 17px;
    }
    &-info {
      color: $primaryGrey;
      margin-top: 4px;
      & span {
        display: inline-block;
        position: relative;
        &:not(:last-child) {
          margin-right: 10px;
          padding-right: 16px;
        }
        &:not(:last-child)::before {
          content: '';
          position: absolute;
          width: 4px;
          height: 4px;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          background: $primaryGrey;
          border-radius: 50%;
        }
      }
    }
    &-desc {
      color: #000000;
      opacity: 0.7;
      margin-top: 36px;
      & p:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    & img {
      max-width: 100%;
    }
    &-promo {
      text-align: center;
      margin-top: 25px;
      & a {
        display: inline-block;
        font-size: 0;
      }
    }
  }
}
