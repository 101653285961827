.phone-number-field {
  padding-top: 10px;
  &__group {
    position: relative;
  }
  & .PhoneInputCountry {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
  }

  & .PhoneInput {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }

  & .PhoneInputInput {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    outline: none;
    padding: 5px 21px 5px 50px;
    border: none;
    border-bottom: 1px solid #d8d6da;

    &:focus {
      border-width: 2px;
      border-color: #000;
    }
  }

  &__label {
    font-size: 16px;
    line-height: 24px;
    color: #9e98a3;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 50px;

    pointer-events: none;
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  &__clear {
    cursor: pointer;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;

    &-hidden {
      display: none;
    }

    &:hover path {
      fill: #cdcacf;
      -webkit-transition: fill 0.5s ease;
      -o-transition: fill 0.5s ease;
      transition: fill 0.5s ease;
    }
  }

  & .PhoneInputInput:focus ~ &__label,
  & .PhoneInputInput:not(:placeholder-shown) ~ &__label {
    font-size: 12px;
    line-height: 16px;
    color: #9e98a3;
    top: -4px;
    left: 0;
  }

  &_error .PhoneInputInput {
    border-color: #f93c10;
  }

  &__error {
    color: #f93c10;
    font-size: 14px;
    margin-top: 5px;
    display: none;
    &_visible {
      display: block;
    }
  }
}
