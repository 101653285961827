@import '../../assets/styles/variables';

.navigation {
  &__container {
    background: #000;
    min-height: calc(100vh); //fallback;
    min-height: calc(100 * var(--vh));
    max-height: calc(100vh); //fallback;
    max-height: calc(100 * var(--vh));
    min-width: 292px;
    width: 292px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__top {
    flex-grow: 1;
  }
  &__logo {
    display: inline-block;
    margin-top: 42px;
    margin-left: 64px;
  }

  margin-top: 80px;
  display: flex;
  flex-direction: column;
  &__item {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    text-decoration: none;
    padding: 9px 64px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:active,
    &:visited {
      color: #fff;
    }
    &:hover,
    &_active {
      background: rgba(255, 255, 255, 0.15);
    }
    &_active {
      color: #ffd200 !important;
    }
  }

  &__user {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 64px;
    padding-right: 40px;
    margin-top: 20px;
    margin-bottom: 44px;
    &-avatar {
      width: 32px;
      height: 32px;
      min-width: 32px;
      max-width: 32px;
      margin-right: 12px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid #fff;
      &.active {
        border: 2px solid $primaryYellow;
      }
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-icon {
      min-width: 32px;
      margin-right: 12px;
    }
    & div {
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
