.link-button {
  width: 100%;
  height: 50px;

  a {
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 2px solid #ffd200;
    -webkit-border-radius: 45px;
    -moz-border-radius: 45px;
    border-radius: 45px;
    box-sizing: border-box;
  }
}
