@import '../../assets/styles/variables';

.startups_page {
  margin-top: 50px;
  margin-bottom: 100px;
  &__title {
  }
}

.startups_active,
.startups_all {
  margin-top: 70px;
  &__preloader {
    height: 100px;
  }
  &__list {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
  }
  &__card {
  }
  &__empty {
    padding-top: 20px;
    &_title {
      color: $primaryGrey;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }
  &__button {
  }
}

.startups_all {
  margin-top: 90px;
}

@media screen and (max-width: 900px) {
  .startups_active,
  .startups_all {
    &__list {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

@media screen and (max-width: 700px) {
  .startups_active,
  .startups_all {
    &__list {
      grid-template-columns: minmax(0, 1fr);
    }
  }
}
