@import '../../../assets/styles/variables';

.wrapper {
  max-width: 360px;
  margin: 0 auto;
}

.vcode {
  width: 100% !important;
  & > div {
    display: flex;
    justify-content: space-between;
    & input {
      width: 56px;
      height: 80px;
      background: #ffffff;
      border-radius: 10px !important;
      font-family: 'GT Walsheim Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    }
  }

  &:global.success input {
    border: 2px solid $primaryYellow !important;
  }
  &:global.error input {
    border: 2px solid $errorRed !important;
  }
}
