@import "../../../assets/styles/variables";

.title {
  color: $primaryGrey;
}

.field {
  margin-top: 20px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
}

.uploads {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 35px;
}

.preloader {
  height: 0;
  margin-top: 30px;
}