@import '../../../assets/styles/variables';

.avatar {
  &__wrap {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  &__active {
    border: 1px solid #ffd200;
    border-radius: 50%;
  }
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  &__image,
  &__initials {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__initials {
    background: #ebeaec;
    display: flex;
    & span {
      color: #9e98a3;
      margin: auto;
    }
  }
}
