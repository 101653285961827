.money-field {
  padding-top: 25px;
  &__input-group {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }

  &__input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 26px;
    line-height: 32px;
    width: 100%;
    border: none;
    outline: none;
    padding: 8px 21px 8px 0;
    border-bottom: 1px solid #ffd200;
    &:disabled {
      background: none;
      color: #000000;
      border-bottom: 1px solid #cccccc;
    }
    &:focus {
      border-bottom: 2px solid #ffd200;
    }
  }

  &__label {
    font-size: 16px;
    line-height: 24px;
    color: #9e98a3;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;

    pointer-events: none;
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  &__clear {
    cursor: pointer;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    &-hidden {
      display: none;
    }
    &:hover path {
      fill: #cdcacf;
      -webkit-transition: fill 0.5s ease;
      -o-transition: fill 0.5s ease;
      transition: fill 0.5s ease;
    }
  }

  &__currency {
    position: absolute;
    top: 50%;
    left: 0;
    font-size: 26px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 32px;
  }

  &__input-filled {
    padding: 8px 21px 8px 21px;
  }

  &__input:focus ~ &__label,
  &__input-filled ~ &__label,
  &__input:not(:placeholder-shown) ~ &__label {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    top: -13px;
    left: 0;
  }

  &_error {
    .money-field {
      &__input {
        border-color: #f93c10;
      }
    }
  }

  &__error {
    color: #f93c10;
    font-size: 14px;
    margin-top: 5px;
    display: none;
    &_visible {
      display: block;
    }
  }
}
