.simple-dropdown {
  &.active {
    & > .simple-dropdown__label svg {
      transform: rotate(0);
      transition: transform 0.2s linear;
    }
    & > .simple-dropdown__content {
      max-height: 10000vh;
      transition: max-height 5s ease-in;
    }
  }

  &__label {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    padding: 7px 0;
    color: #000;
    &:hover {
      opacity: 0.7;
    }
    & p {  cursor: pointer; };
    & svg {
      cursor: pointer;
      transform: rotate(180deg);
      margin-right: 10px;
      transition: transform 0.2s linear;
      margin-top: 8px;
    }
  }
  &__content {
    overflow: hidden;
    max-height: 0;
    //transition: max-height 3s ease-out;
    & a {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
    &-inner {
      color: #9E98A3;
      //padding: 10px 0 10px 24px;
      padding-left: 24px;
      & p:not(:first-child) {
        margin-top: 15px;
      }
    }
  }
}