.container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  & a:hover {
    opacity: 0.8;
  }
  & svg {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}