.form {
  max-width: 352px;
}

.reason {
  margin-top: 30px;
  color: #D80027;
}

.inner {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 352px;
  margin: 30px 0;
}

.controls {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  & :global .round-link {
    padding: 6px 23px;
  }& :global .round-button {
    padding: 6px 23px;
  }
}