@import '../../assets/styles/variables';
$size: 260px;

.avatar-edit {
  clip-path: circle(50%);
  position: relative;
  &__error {
    border: 1px solid $errorRed;
  }
  &__container {
    width: $size;
    height: $size;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    &:hover {
      & .avatar-edit__rotate {
        display: block;
      }
    }
  }

  &__rotate {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  &__remove {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background: #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &-icon path {
      fill: #fff;
    }
  }
}
