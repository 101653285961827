@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.wrapper {
}

.mobile_logo {
  height: 70px;
  max-height: 70px;
  overflow: hidden;
  margin-bottom: 10px;
  display: none;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
}
.fundraising {
  align-items: center;
  justify-content: flex-start;
  display: none;
  &.desktop,
  &.mobile {
    display: flex;
  }
  &.mobile {
    margin-top: 10px;
  }
}

.top {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 220px;
  grid-column-gap: 30px;
  &_right {
    text-align: right;
    max-height: 110px;
    overflow: hidden;
  }
  &_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
  }
  &_title {
    margin-right: 20px;
    @include clampText(2);
  }
  &_goal {
    margin-right: 4px;
  }
  &_goal {
    background: #ebeaec;
    border-radius: 130px;
    padding: 3px 13px;
  }
}

.info {
  color: $primaryBlack;
  margin-top: 8px;
  & span {
    display: inline-block;
    position: relative;
    &:not(:last-child) {
      margin-right: 10px;
      padding-right: 16px;
    }
    &:not(:last-child)::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      background: $primaryGrey;
      border-radius: 50%;
    }
  }
}

.site {
  margin-top: 8px;
  display: inline-block;
}

.logo {
  height: 100%;
  display: inline-block;
  overflow: hidden;
  max-height: 220px;
  & img {
    max-width: 100%;
    max-height: 100%;
  }
}

.founders {
  margin-top: 40px;
  &_list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 20px;
    margin-top: 20px;
  }
  &_title {
  }
}

.details {
  margin-top: 40px;
  max-width: 656px;
  &_text {
    color: #000;
    opacity: 0.7;
    margin-top: 8px;
  }
}

.image {
  text-align: center;
  margin-top: 25px;
  & img {
    max-width: 100%;
  }
  & a {
    display: inline-block;
    font-size: 0;
  }
}

@media screen and (max-width: 900px) {
  .mobile_logo {
    display: block;
  }

  .top {
    grid-template-columns: minmax(0, 1fr);
    &_right {
      display: none;
    }
  }

  .founders {
    &_list {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

@media screen and (max-width: 630px) {
  .founders {
    &_list {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
