@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.co-founder-invitation {
  @include modalMinSize();
  &__desc {
    color: $primaryGrey;
    margin-top: 10px;
  }
  & input {
    display: block;
    width: 100%;
    margin-top: 5px;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid #9e98a3;
    outline: none;
  }
  &__buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__copy-btn {
    max-width: 62%;
  }
}
