.sign-up-co-founder {
  box-sizing: border-box;
  margin: 17px auto;
  max-width: 352px;
  width: 100%;
  &__error,
  &__preloader {
    min-height: 60vh;
  }
  &__error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    &-desc {
      margin-top: 15px;
    }
  }
  &__step-number {
    font-size: 12px;
    line-height: 16px;
    color: #9e98a3;
  }
}
