@import '../../assets/styles/variables';

.profile {
  &__notifications {
    margin-bottom: 50px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &-info {
      flex-grow: 1;
      overflow: hidden;
    }
    &-avatar {
      margin-right: 50px;
      position: relative;
      &:hover &-camera {
        display: inline-block;
      }
      &-camera {
        display: none;
        cursor: pointer;
        position: absolute;
        height: 27px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        &:hover svg {
          opacity: 0.8;
        }
      }
    }
    &-top {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(161px, 161px);
      grid-column-gap: 20px;
      &-left {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      &-inner {
        margin-right: 20px;
        overflow: hidden;
      }
    }
    &-goal div:first-child,
    &-income div:first-child,
    &-loan div:first-child {
      color: $primaryGrey;
    }
    &-socials {
      display: flex;
      justify-content: flex-start;
      margin-top: 11px;
      & a:not(:last-child) {
        margin-right: 7px;
      }
    }
    &-income {
      margin-top: 10px;
    }
    &-fundraising {
      margin-top: 12px;
      display: flex;
      justify-content: flex-start;
    }
    &-loan {
      margin-left: 48px;
    }
  }

  &__personal {
    &-wrap {
      margin-top: 55px;
    }
    border-top: 1px solid #9e98a325;
    padding: 37px 0;
    margin-top: 20px;
    &-empty {
      color: $darkGrey;
      margin-top: 10px;
    }
    &-phone {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      &-verify {
        padding: 3px 20px;
      }
    }
    &-contacts,
    &-about {
      &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 -12px;
        & > div {
          color: $darkGrey;
          margin: 8px 12px;
        }
      }
    }
    &-contacts {
      padding-top: 20px;
      &-list {
        flex-direction: column;
        & .row {
          justify-content: flex-start;
          margin: 0;
          & > div {
            color: $darkGrey;
            margin: 8px 12px;
          }
        }
      }
      &-phone {
        display: inline-block;
        padding-top: unset;
        .PhoneInputCountry,
        .phone-number-field__label {
          display: none;
        }
        .PhoneInputInput {
          border: none;
          padding: 0;
          background: unset !important;
        }
      }
    }
    &-summary {
      margin-top: 20px;
      & h3:not(:first-child) {
        margin-top: 20px;
      }
      &-text {
        color: $darkGrey;
        margin-top: 5px;
      }
    }
  }

  &__career,
  &__education {
    margin-top: 28px;
  }

  &__career,
  &__education {
    &-list {
      margin-top: 10px;
      & li {
        color: $darkGrey;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: flex-start;
        & div:first-child {
          min-width: 180px;
        }
        & div:last-child {
          width: 100%;
        }
        &:hover .bullet {
          opacity: 1;
          &::after {
            width: 14px;
            height: 14px;
            background: url('../../assets/images/icon_edit.svg') no-repeat;
            background-size: cover;
            border-radius: unset;
          }
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }

  &__add-btn {
    background: $primaryYellow;
    border-radius: 13px;
    padding: 3px 12px;
    display: block;
    margin-top: 10px;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }

  &__education-list li div:first-child {
    min-width: 140px;
  }

  &__recommendations {
    &-wrap {
      margin-top: 23px;
    }
    border-top: 1px solid #e6e5e7;
    padding: 31px 0;
    margin-top: 20px;
  }

  &__review {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    & h6 {
      margin-top: 5px;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &-content {
      flex-grow: 1;
    }
    &-comment {
      margin-top: 8px;
    }
    &-avatar {
      margin-right: 20px;
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-socials {
      display: flex;
      align-items: center;
      margin-left: 12px;
      & svg,
      & a {
        width: 20px;
        height: 20px;
      }
      & a:not(:last-child) {
        margin-right: 4px;
      }
    }
    &-comment {
      color: #000000;
      opacity: 0.7;
      font-style: italic;
    }
  }

  &__startup {
    border: 2px solid #e6e5e7;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 67px 118px 80px;
    margin-top: 20px;
    &-empty {
      color: $darkGrey;
      margin-top: 10px;
    }
    &-logo {
      display: block;
      margin-top: -27px !important;
      & img {
        width: 100%;
        height: 100%;
        max-height: 70px;
        object-fit: contain;
      }
    }
    &-wrap {
      padding-top: 48px;
    }
    &-header {
      text-align: center;
      & a {
        margin-top: 4px;
        display: inline-block;
      }
    }
    &-position {
      margin-top: 17px;
    }
    &-address {
      color: $darkGrey;
      margin-top: 15px;
      text-align: center;
    }
    &-founders {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 15px;
    }
    &-founder {
      text-align: center;
      padding: 20px;
      max-width: 200px;
      &-role {
        color: $primaryGrey;
      }
    }
    &-link {
      display: block;
      margin: 15px auto 0;
      padding: 6px 22px;
    }
    &-warning {
      justify-content: center;
      margin-top: 20px;
    }
    &-info {
      color: $primaryGrey;
      margin-top: 4px;
      & span {
        display: inline-block;
        position: relative;
        &:not(:last-child) {
          margin-right: 10px;
          padding-right: 16px;
        }
        &:not(:last-child)::before {
          content: '';
          position: absolute;
          width: 4px;
          height: 4px;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          background: $primaryGrey;
          border-radius: 50%;
        }
      }
    }
    &-desc {
      color: #000000;
      opacity: 0.7;
      margin-top: 36px;
      & p:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    & img {
      max-width: 100%;
    }
    &-promo {
      text-align: center;
      margin-top: 25px;
      & a {
        display: inline-block;
        font-size: 0;
      }
    }
  }
}
