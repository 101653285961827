.link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  svg {
    width: 32px;
    height: 32px;
  }
}
