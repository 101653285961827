.root_page {
  display: flex;
  min-height: calc(100vh); //fallback;
  min-height: calc(100 * var(--vh));
  & main {
    flex-grow: 1;
    overflow: auto;
    min-height: calc(100vh); //fallback;
    min-height: calc(100 * var(--vh));
    max-height: calc(100vh); //fallback;
    max-height: calc(100 * var(--vh));
  }

  & .footer {
    display: flex;
    align-items: center;
    & .container {
      max-width: 1068px;
      min-width: 320px;
      padding: 0 88px;
      width: 100%;
      margin: unset;
    }
  }

  &__warning {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1380px) {
  .root_page {
    flex-direction: column;
    & main {
      max-height: unset;
      overflow: unset;
    }
  }
}
