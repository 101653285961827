.label {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--Neutral-Colors-Black-400, #000);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;

}


.checkbox {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 24px;
  min-width: 24px;
  height: 24px;

  &__input {
    position: absolute;
    display: none;
    opacity: 0;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border: 2px solid #D9D9D9;
    border-radius: 4px;
    & svg {
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover &__input ~ &__checkmark {
    opacity: 0.7;
  }

  &.disabled:hover &__input ~ &__checkmark {
    opacity: 1;
  }

  &__input:checked ~ &__checkmark {
    background-color: #FFD200;
    border: 1px solid #FFD200;
  }

  &__input:checked ~ &__checkmark svg {
    opacity: 1;
  }

  &__input:checked ~ &__checkmark:after {
    display: block;
  }
}