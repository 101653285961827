@import '../../assets/styles/variables';

.scr-list {
  & li {
    cursor: pointer;
    padding-left: 20px;
    position: relative;
    color: #000000;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 8px;
      width: 8px;
      height: 8px;
      background: $primaryYellow;
      border-radius: 50%;
    }
  }
}
