@import '../../assets/styles/variables';

.checkbox-list {
  &__title {
    margin-bottom: 10px;
    color: $primaryGrey;
  }
}

.s-checkbox {
  position: relative;
  cursor: pointer;
  min-height: 24px;
  padding-top: 3px;
  padding-left: 35px;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background-color: #ebeaec;
    border-radius: 6px;
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 12px;
      top: -4px;
      width: 9px;
      height: 23px;
      border: solid #000;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  &:hover &__input ~ &__checkmark {
    opacity: 0.7;
  }
  &__input:checked ~ &__checkmark {
    background-color: $primaryYellow;
  }
  &__input:checked ~ &__checkmark:after {
    display: block;
  }
}
