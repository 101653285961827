.verify-user {
  margin-top: 15px;
  & > p {
    font-size: 16px;
    color: #000;
    line-height: 24px;
    margin: 0;

    &:first-child,
    &:last-of-type {
      padding-bottom: 30px;
    }
  }

  .veriff-submit {
    background-color: #ffd200;
    color: #000;
  }

  .veriff-information {
    padding: 30px 0;

    & > div {
      display: flex;
      padding-bottom: 25px;
    }

    p {
      font-size: 16px;
      color: #9e98a3;
      line-height: 20px;
      margin: 0;
      padding-left: 15px;

      a {
        color: #9e98a3;
      }
    }
  }
}
