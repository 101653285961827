.footer {
  background-color: #191919;
  margin-top: 50px;
  padding: 50px 0;
  display: flex;

  & .container {
    height: 100%;
    width: 100%;
  }

  &__inner {
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    ul {
      .footer_logo {
        margin-bottom: 25px;
      }

      h4 {
        color: #fff;
        margin-bottom: 20px;
      }

      p {
        color: #757575;
        margin-bottom: 15px;
      }

      li {
        color: #757575;
        cursor: pointer;
        margin-bottom: 15px;

        &:hover {
          color: #ffd200;
          transition: color 0.2s ease-in-out;
        }
      }
    }
  }

  hr {
    width: 100%;
    border: 1px solid #757575;
  }

  &__content-copyright {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;

    & p {
      margin: 0;
      font-size: 12px;
      line-height: 16px;
      color: #9e98a3;

      &:first-child {
        margin-bottom: 8px;
        margin-right: 25px;
      }
    }
  }

  &__content-social {
    margin-top: 15px;
  }
}

@media (max-width: 600px) {
  .footer {
    min-height: unset;
    padding: 24px 0;
    &__inner {
      align-items: flex-start;
    }

    &__content-copyright {
      margin-top: 20px;
    }
  }
}
