$primaryYellow: #ffd200;
$primaryBlack: #000;
$primaryWhite: #ffffff;
$primaryGrey: #9e98a3;
$disabledGrey: #f4f4f5;
$darkGrey: #4c4c4c;

$errorRed: #f93c10;
$passGreen: #43b129;

$c-grey-f4f4f5: #f4f4f5;
