.sign-up {
  box-sizing: border-box;
  margin: 17px auto;
  max-width: 352px;
  width: 100%;
  &__step-number {
    font-size: 12px;
    line-height: 16px;
    color: #9e98a3;
  }
}
