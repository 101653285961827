.round-link {
  box-sizing: border-box;
  border-radius: 70px;
  border: 2px solid #ffd200;
  padding: 8px 22px;
  outline: none;
  background: transparent;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  &__filled {
    background: #ffd200;
  }
  &__label {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
  &__fullwidth {
    width: 100%;
  }
}
