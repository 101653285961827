@import '../../../assets/styles/variables';

.fundraising-start-module {
  &__notifications {
    margin-top: 15px;
  }
  &__dashboard {
    margin-top: 60px;
  }
}

.custom-partners-select-list {
  &__container {
    margin-top: 60px;
  }
  &__empty,
  &__desc {
    color: $primaryGrey;
    margin-top: 12px;
  }
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 20px;
  &__item {
    overflow: hidden;
  }
  &__save {
    margin-top: 60px;
  }
}

.general-partners-list {
  &__container {
    margin-top: 60px;
  }
  &__empty {
    color: $primaryGrey;
    margin-top: 12px;
  }
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 20px;
  &__item {
    overflow: hidden;
  }
  &__save {
    margin-top: 60px;
  }
}
