@import '../../assets/styles/variables';

.payments-next-page {
  margin-top: 68px;
  margin-bottom: 60px;
  &__no-payment {
    margin-top: 20px;
    color: #000;
  }
  &__desc {
    margin-top: 10px;
    width: 85%;
  }
  &__link {
    background: $primaryYellow;
    min-width: 125px;
  }
  &__main {
    margin-top: 20px;
  }
  &__schedule {
    margin-top: 80px;
    &-preloader {
      height: 150px;
    }
    &-desc {
      margin-top: 30px;
      color: $primaryGrey;
      & p:not(:first-child) {
        margin-top: 15px;
      }
    }
    &-block {
      margin-top: 20px;
    }
  }
}
