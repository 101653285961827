@import '../../assets/styles/variables';

.bank_requisite {
  &__bank-details {
    padding: 34px 28px;
    border: 2px solid #ffd200;
    box-sizing: border-box;
    border-radius: 8px;
    align-self: flex-start;
    min-width: 350px;
    max-width: 350px;
    h3 {
      margin: 0;
    }
    &-list {
      margin: 0;
      padding: 0;
      margin-top: 30px;

      &-item {
        box-sizing: border-box;
        display: block;
        list-style: none;
        border-bottom: 1px solid #d8d6da;
        position: relative;
        padding-right: 35px;
        padding-bottom: 5px;
        margin: 0;
        &:hover {
          border-bottom: 1px solid $primaryYellow;
          & .bank_requisite__bank-details-list-value {
            opacity: 0.7;
          }
        }
        &:not(:last-child) {
          margin-bottom: 18px;
        }
        & .copy_button {
          position: absolute;
          right: 0;
          top: 11px;
        }
      }

      &-title {
        font-size: 12px;
        line-height: 16px;
        color: #9e98a3;
        white-space: nowrap;
      }

      &-value {
        font-size: 16px;
        line-height: 24px;
      }
      &-note {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      &-required {
        background: #000000;
        width: 70px;
        height: 20px;
        padding-bottom: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 130px;
        position: absolute;
        right: 33px;
        top: 13px;
        color: $primaryWhite;
      }
    }
  }
}
