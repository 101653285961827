@import '../../assets/styles/variables';

.founders-page {
  margin-top: 50px;
  margin-bottom: 60px;
}

.founders-active {
  margin-top: 70px;
  &__preloader {
    height: 100px;
  }
  &__list {
    padding-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 20px;
  }
  &__card {
    margin: 20px 24px;
  }

  &__empty {
    padding-top: 20px;
    &-title {
      color: $primaryGrey;
    }
    & a {
      margin-top: 10px;
    }
  }

  & .swap-partner {
    background: #fff !important;
  }
}

.founders-all {
  &.active {
    margin-top: 90px;
  }
  & .avatar {
    border: 4px solid #fff;
  }
  &__preloader {
    height: 100px;
  }
  &__list {
    padding-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 20px;
  }
  &__card {
    margin: 20px;
  }
  &__btn {
    margin-top: 40px;
    width: 100%;
  }
  &__empty {
    padding-top: 20px;
    &-title {
      color: $primaryGrey;
    }
  }
}
