.swap-guarantee-page {
  padding: 50px 0;

  &__list {
    margin-top: 35px;
    &-item {
      margin-top: 25px;
      position: relative;
      padding-left: 40px;
      &::before {
        content: '';
        width: 30px;
        height: 30px;
        background: #ffd200;
        position: absolute;
        border-radius: 50%;
        top: 0;
        left: 0;
      }
    }
  }

  &__financing {
    margin-top: 60px;
    & h3 {
      margin-top: 15px;
    }
  }
}
