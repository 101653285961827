@import '../../../assets/styles/variables';

.warning_block {
  display: flex;
  align-items: center;
  &__icon {
    margin-right: 8px;
  }
  &.border {
    background: #9e98a310;
    border-radius: 8px;
    padding: 16px 20px;
  }
}
