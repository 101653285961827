.round-button {
  box-sizing: border-box;
  border-radius: 70px;
  border: 2px solid #ffd200;
  padding: 10px 22px;
  outline: none;
  background: transparent;
  cursor: pointer;
  &__label {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    display: block;
  }

  &_filled {
    background: #ffd200;
  }
  &_fullwidth {
    width: 100%;
  }
  &:disabled {
    border: 2px solid #f4f4f5;
    background: #f4f4f5;
  }
  &:disabled &__label {
    color: #929293;
  }
}
