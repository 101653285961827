@import '../../../../../../assets/styles/variables';

$gridTemplateColumn: minmax(70px, 70px) repeat(4, minmax(0, 1fr));
$gridGap: 30px;

.example_table {
  margin-top: 40px;
  &__wrap {
    margin: 0 -20px;
  }
  &__inner {
    padding: 0 20px;
    width: 100%;
    overflow: auto;
  }
  &__thead,
  &__tbody_row_summary,
  &__tbody_row {
    display: grid;
    grid-template-columns: $gridTemplateColumn;
    grid-column-gap: $gridGap;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  &__thead {
    align-items: center;
    &_col {
      padding: 15px 0;
      color: $primaryGrey;
    }
  }
  &__tbody {
    &_row,
    &_row_summary {
      border-top: 1px solid #e6e5e7;
      padding: 15px 0;
    }
    &_col {}
    &_row_summary {
      border-top: 1px solid #000000;
    }
  }
}
