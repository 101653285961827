.forgot-password_form {
  &__title {
    margin: 0;
    font-size: 34px;
    line-height: 40px;
  }
  &__description {
    margin: 16px 0 0 0;
    font-size: 16px;
    line-height: 24px;
  }
  &__email,
  &__submit {
    margin-top: 30px;
  }

  &__bottom {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
