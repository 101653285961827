.fundraising-module {
  &__wrap {
    padding-bottom: 100px;
  }
  &__progressbar {
    margin: 70px 0;
  }
  &__content.co_founder {
    margin-top: 70px;
  }
  &__preloader {
    height: 80vh;
  }
  &__content {
    margin-top: 70px;
  }
}
