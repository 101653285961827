@import '../../assets/styles/variables';

.notification-block {
  &__list {
    width: 100%;
    & li {
      font-size: 20px;
      line-height: 24px;
      position: relative;
      padding-left: 27px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      &::before {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        left: 0;
        top: 5px;
        border-radius: 50%;
        background: $errorRed;
      }
    }
  }
}
