.wrapper {
  position: relative;
}

.input {
  & :global .input-text-field__input {
    padding: 5px 52px 5px 0;
  }
}

.icon {
  position: absolute;
  top: 14px;
  right: 0;
}