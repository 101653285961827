@import '../../assets/styles/variables';

.wrapper {
  margin-top: 68px;
  margin-bottom: 60px;
}

.warnings {
  margin-top: 20px;
}

.preloader {
  margin-top: 20px;
  height: 40vh;
}

.history {
  margin-top: 50px;
}

.description {
  margin-top: 30px;
}
