.help-page {
  padding: 50px 0 70px;
  & h2 {
    margin-top: 40px;
  }
  &__title {
    margin-bottom: 50px;
  }
  & h2 + div {
    margin-top: 15px;
  }
}
