@import '../../../assets/styles/variables';

.funds-withdraw-page {
  margin-top: 45px;
  margin-bottom: 50px;
}

.withdraw-form {
  &__from,
  &__to {
    color: #000 !important;
  }

  &__to {
    margin-top: 40px;
  }

  &__frozen {
    margin-top: 20px;
  }

  &__select {
    flex-grow: 1;
    margin-top: 10px;
    min-width: 460px;
    max-width: 460px;
  }

  &__amount {
    margin-top: 50px;
    max-width: 460px;
  }

  &__commission {
    margin-top: 15px;
    color: #9e98a3;
  }

  &__add_bank_account {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    max-width: 460px;
    & .round-link {
      padding: 8px 22px;
    }
  }

  & .round-button {
    margin-top: 32px;
    padding: 10px 43px;
  }

  &__support {
    margin-top: 50px;
    color: #9e98a3;
    & a {
      text-decoration: underline;
    }
  }
  &__cofounder {
    margin-bottom: 20px;
  }
}
