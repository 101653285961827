@import '../../assets/styles/variables';

.payment_block {
  padding: 30px;
  display: grid;
  grid-template-columns: minmax(240px, 1fr) 1fr;
  grid-column-gap: 20px;
  border: 2px solid #9e98a3;
  background: #fff;
  border-radius: 8px;
  &__left {
    & > div:not(:first-child) {
      margin-top: 5px;
    }
  }
  &__main {
    border: 2px solid #000;
  }
  &__error {
    color: $errorRed;
  }

  &__amount {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & p {
      max-width: 360px;
      color: #000 !important;
    }
  }

  & div p:first-child {
    color: $primaryGrey;
  }
}


.fundraisings {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
}

.fundraising {}