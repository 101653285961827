@import '../../assets/styles/variables';

.activity-module {
  padding-top: 50px;
  padding-bottom: 50px;

  $gridTemplateColumn: minmax(220px, 220px) 1fr minmax(160px, 160px);
  $gridTemplateColumn4: minmax(220px, 220px) 1fr minmax(160px, 160px) 1fr;
  $gridGap: 30px;

  &__table {
    margin-top: 25px;
  }

  &__empty {
    color: $primaryGrey;
    margin-top: 25px;
  }

  &__thead,
  &__tbody-row {
    display: grid;
    grid-template-columns: $gridTemplateColumn;
    grid-column-gap: $gridGap;
    align-items: center;
    justify-content: space-between;
  }

  &__thead.cols_4,
  &__tbody-row.cols_4 {
    grid-template-columns: $gridTemplateColumn4;
  }

  &__thead {
    border-bottom: 1px solid #e6e5e7;
    &-col {
      padding: 20px 0;
      font-size: 14px;
      line-height: 20px;
      color: #9e98a3;
    }
  }

  &__tbody {
    &-row {
      border-bottom: 1px solid #e6e5e7;
    }
    &-col {
      padding: 23px 0;
      overflow: hidden;
    }
  }

  &__more-btn {
    margin-top: 40px;
  }

  &__download {
    margin-top: 40px;
    display: flex;
    gap: 10px;
    & ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
    }
    & a {
      text-decoration: underline;
    }
    & a:hover {
      opacity: 0.8;
    }
  }
}
