@import '../../../assets/styles/variables';

.activity_filters {
  margin-top: 20px;
  &__row {
    display: grid;
    grid-template-columns: minmax(120px, 120px) minmax(120px, 120px) minmax(120px, 100px) 1fr minmax(
        130px,
        130px
      );
    grid-column-gap: 20px;
    align-items: flex-end;
    &_bottom {
      margin-top: 20px;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
    }
  }

  .checkbox__checkmark {
    width: 20px;
    height: 20px;
    top: 2px;
    &::after {
      left: 10px;
      top: -2px;
      width: 7px;
      height: 17px;
    }
  }

  .checkbox.disabled .checkbox__input ~ .checkbox__checkmark,
  .checkbox.disabled .checkbox__input:checked ~ .checkbox__checkmark {
    background-color: $primaryWhite !important;
    border: 1px solid #cdcdcd !important;
  }

  .checkbox.disabled.checked .checkbox__input:checked ~ .checkbox__checkmark {
    background-color: $primaryYellow !important;
    border: 1px solid $primaryYellow !important;
  }

  .checkbox__input:checked ~ .checkbox__checkmark {
    background-color: $primaryYellow !important;
    border: 1px solid $primaryYellow !important;
  }

  &__group {}

  &__submit {
    padding: 5px 0;
  }
}

@media only screen and (max-width: 800px) {
  .activity_filters {
    &__row {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 20px;
    }
  }
}

@media only screen and (max-width: 725px) {
  .activity_filters {
    &__row {
      grid-template-columns: minmax(0, 1fr);
      grid-gap: 20px;
    }
  }
}
