.wrapper {}
.content {
  padding: 0 0 10px;
}
.disclaimer {
  margin-top: 15px;
}
.checkboxes {
  margin-top: 20px;
}
.button {
  margin-top: 24px;
}
.submit {}