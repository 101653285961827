@import '../../../assets/styles/variables';

.profile-education-form {
  box-sizing: border-box;
  width: 500px;
  padding: 40px 70px 230px;
  &__title {
    margin: 7px 0 40px 0;
  }
  &__field {
    margin-top: 15px;
  }
  &__calendar {
    margin-top: 10px;

    .Calendar {
      position: absolute;
    }
    &-reset {
      width: 80%;
      display: block;
      margin: 0 auto 20px !important;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  &__button {
    max-width: 150px;
    margin-top: 33px;
    &:first-child {
      margin-right: 15px;
    }
  }
}

.education-item {
  &__dates {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $primaryGrey;

    & button.error {
      color: $errorRed;
    }
    & button.black,
    & button:hover {
      color: $primaryBlack;
    }
    & div {
      margin: 0 15px;
    }
  }
}
