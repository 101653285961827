@import '../../../assets/styles/variables';

.date-select {
  position: relative;
  &__input {
    border-bottom: 1px solid #d8d6da;
  }
  &.error &__input {
    border-bottom: 1px solid $errorRed;
  }
  &__label {
    color: $primaryGrey;
  }
  &__placeholder {
    padding: 2px 0;
    cursor: pointer;
  }
  &__reset {
    color: $primaryGrey;
    &:hover {
      color: $primaryBlack;
    }
    &-wrap {
      padding: 0 2em !important;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__calendar {
    &-wrap {
      max-height: 0;
      overflow: hidden;
      & .Calendar {
        margin-top: 5px;
        &__footer {
          bottom: 15px;
        }
      }
      &.active {
        max-height: 1000px;
        position: absolute;
        overflow: visible;

        @media screen and (max-width: 425px) {
          right: 5px;
        }
      }
    }
  }
  &__error {
    color: $errorRed;
    margin-top: 5px;
  }
}
