@import '../../assets/styles/variables';

.dash-payment-info {
  padding: 30px;
  border: 2px solid #000;
  border-radius: 8px;
  overflow: hidden;
  min-height: 212px;
  &__pay {
    font-size: 40px!important;
  }
  &__pay,
  &__date {
    color: #000;
  }
  &__block {
    & p:first-child {
      color: $primaryGrey;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
