@import '../../assets/styles/variables';

.founder-detail-page {
  margin-top: 60px;
  margin-bottom: 100px;
  &__back {
    margin-bottom: 30px;
  }
  &__preloader {
    height: 60vh;
  }
}
