.password-field {
  padding-top: 10px;
  &__input-group {
    box-sizing: border-box;
    position: relative;
  }

  &__input {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    border: none;
    outline: none;
    padding: 5px 21px 5px 0;
    border-bottom: 1px solid #d8d6da;
    &:focus {
      border-bottom: 2px solid #000;
    }
  }

  &__label {
    font-size: 16px;
    line-height: 24px;
    color: #9e98a3;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;

    pointer-events: none;
    transition: all 0.25s ease;
  }

  &__clear {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    &-hidden {
      display: none;
    }
    &:hover path {
      fill: #cdcacf;
      transition: fill 0.5s ease;
    }
  }

  &__input:focus ~ &__label,
  &__input-filled ~ &__label,
  &__input:not(:placeholder-shown) ~ &__label {
    font-size: 12px;
    line-height: 16px;
    color: #9e98a3;
    top: -4px;
    left: 0;
  }

  &__input:-webkit-autofill ~ &__label {
    font-size: 12px;
    line-height: 16px;
    color: #9e98a3;
    top: -4px;
    left: 0;
  }

  &__bottom {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__rules {
    flex-grow: 1;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    &_hidden {
      display: none;
    }
    & .valid::after {
      background: #69be28;
    }
    & li {
      font-size: 12px;
      line-height: 16px;
      color: #9e98a3;
      padding-left: 15px;
      position: relative;
      list-style: none;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
      &::after {
        content: '';
        background: #d8d6da;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        left: 0;
        width: 8px;
        height: 8px;
      }
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    align-content: flex-start;
    cursor: pointer;
    & svg {
      margin-right: 4px;
    }
  }

  &_error {
    .password-field {
      &__input {
        border-color: #f93c10;
      }
    }
  }

  &__error {
    color: #f93c10;
    font-size: 14px;
    margin-top: 5px;
    display: none;
    &_visible {
      display: block;
    }
  }
}
