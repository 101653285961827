.label {
  font-size: 16px;
  line-height: 24px;
  color: #9E98A3;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  pointer-events: none;
  -webkit-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;

  &__focused {
    font-size: 12px;
    line-height: 16px;
    color: #9E98A3;
    top: -4px;
    left: 0;
    transition: all .25s ease;
  }
}