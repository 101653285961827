.back-button {
  outline: none;
  color: #9e98a3;
  & svg {
    transform: rotate(180deg);
    margin-right: 12px;
    & path {
      fill: #9e98a3;
    }
  }
}
