.file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background: #333333;
  position: relative;
  border-radius: 70px;
  padding: 10px 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
}

.remove {
  width: 24px;
  min-width: 24px;
  height: 24px;
  display: flex;
  & svg {
    margin: auto;
  }
}