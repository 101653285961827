@import '../../../../assets/styles/variables';

.page {
  padding: 50px 0;
  &__list {
    margin-top: 25px;
    &_item {
      margin-top: 25px;
      position: relative;
      padding-left: 45px;
      &::before {
        content: '';
        width: 30px;
        height: 30px;
        background: #ffd200;
        position: absolute;
        border-radius: 50%;
        top: 0;
        left: 0;
      }
    }
  }
  &__image {
    max-width: 750px;
    width: 100%;
    height: auto;
    margin-top: 30px;
  }
  & h2 {
    margin-top: 40px;
  }
  &__desc {
    margin-top: 15px;
  }
  &__notes {
    margin-top: 25px;
    & p:not(:first-child) {
      margin-top: 15px;
    }
  }
}
