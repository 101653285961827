.question {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  &.active {
    .question__label svg {
      transform: rotate(0);
      transition: transform 0.2s linear;
    }
    .question__content {
      max-height: 10000px;
      transition: max-height 5s ease-in;
    }
  }
  &__label {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    & p {
      cursor: pointer;
    }
    & svg {
      cursor: pointer;
      transform: rotate(180deg);
      margin-right: 10px;
      transition: transform 0.2s linear;
    }
  }
  &__content {
    &-inner {
      color: #9e98a3;
      padding: 10px 0 10px 24px;
      & p:not(:first-child) {
        margin-top: 15px;
      }
    }
    overflow: hidden;
    //border: 1px solid red;
    max-height: 0;
    //transition: max-height 3s ease-out;
  }
}

.faq-link {
  color: #9e98a3;
  margin-top: 15px;
  display: inline-block;
}

.faq-list-decimal {
  list-style-type: decimal;
  list-style-position: inside;
  & > li {
    margin-top: 4px;
  }
}

.faq-list-dash {
  & > li {
    &::before {
      content: '-';
      margin-right: 5px;
    }
    margin-top: 4px;
  }
}

.faq-list-dot {
  & > li {
    margin-top: 10px;
    position: relative;
    padding-left: 15px;
    &::before {
      content: '';
      width: 7px;
      height: 7px;
      background: #ffd200;
      border-radius: 50%;
      top: 8px;
      position: absolute;
      left: 0;
    }
    margin-left: 20px;
  }
}
