@import '../../assets/styles/variables';

.dash-balance-info {
  padding: 30px;
  background: #000;
  border-radius: 8px;
  overflow: hidden;
  min-height: 212px;
  &__cash {
    color: $primaryYellow;
    font-size: 40px!important;
  }
  &__loan {
    color: #fff;
  }
  &__block {
    & p:first-child {
      color: #fff;
      opacity: 0.7;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
