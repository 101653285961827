@import 'assets/styles/reset';
@import 'assets/styles/variables';

@font-face {
  font-family: 'GT Walsheim Pro';
  src: local('GT Walsheim Pro Regular'), local('GT-Walsheim-Pro-Regular'),
    url('assets/fonts/GTWalsheimPro-Regular.woff2') format('woff2'),
    url('assets/fonts/GTWalsheimPro-Regular.woff') format('woff'),
    url('assets/fonts/GTWalsheimPro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GT-Walsheim-Pro';
  src: url('./assets/fonts/GT-Walsheim-Pro-Regular.eot');
  src: local('☺'), url('./assets/fonts/GT-Walsheim-Pro-Regular.woff') format('woff'),
    url('./assets/fonts/GT-Walsheim-Pro-Regular.ttf') format('truetype'),
    url('./assets/fonts/GT-Walsheim-Pro-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: local('GT Walsheim Pro Medium'), local('GT-Walsheim-Pro-Medium'),
    url('assets/fonts/GTWalsheimPro-Medium.woff2') format('woff2'),
    url('assets/fonts/GTWalsheimPro-Medium.woff') format('woff'),
    url('assets/fonts/GTWalsheimPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@mixin fontFamilyCookie {
  font-family: 'GT-Walsheim-Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

@mixin fontFamily {
  font-family: 'GT Walsheim Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

* {
  @include fontFamily();
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;

  min-width: 1024px;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#onetrust-banner-sdk.otFlat {
  z-index: 9999999999 !important;
}

.container {
  max-width: 1120px;
  min-width: 320px;
  margin: 0 auto;
  padding: 0 24px;
  width: 100%;
}

.container-inner {
  max-width: 1068px;
  min-width: 320px;
  padding: 0 88px;
  width: 100%;

  @media screen and (max-width: 1380px) {
    margin: 0 auto;
    padding: 0 60px;
  }
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

.f-400 {
  font-weight: 400;
}

.f-500 {
  font-weight: 500;
}

.f-600 {
  font-weight: 600;
}

.f-26 {
  font-size: 26px;
  line-height: 32px;
}

.f-28 {
  font-size: 28px;
  line-height: 32px;
}

.f-12 {
  font-size: 12px;
  line-height: 16px;
}

.f-14 {
  font-size: 14px;
  line-height: 20px;
}

.f-16 {
  font-size: 16px;
  line-height: 24px;
}

.f-18 {
  font-size: 18px;
  line-height: 20px;
}

.f-20 {
  font-size: 20px;
  line-height: 24px;
}

.f-22 {
  font-size: 22px;
  line-height: 28px;
}

.f-24 {
  font-size: 24px;
  line-height: 32px;
}

.f-32 {
  font-size: 32px;
  line-height: 32px;
}

.f-34 {
  font-size: 34px;
  line-height: 40px;
}

.f-42 {
  font-size: 42px;
  line-height: 48px;
}

.ReactModal__Content {
  inset: 50% 50% auto 50% !important;

  @media screen and (max-width: 425px) {
    width: 90% !important;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
}

.c-grey {
  color: $primaryGrey;
}

.nowrap {
  white-space: nowrap;
}

.tl {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hov:hover {
  opacity: 0.75;
}

.dfc {
  display: flex;
  align-items: center;
}

.dfs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.line2tl {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.underline {
  text-decoration: underline;
}

.clearfix:after {
  content: ' '; /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.bullet {
  position: relative;
  padding-left: 20px;
  &::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $primaryYellow;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* Calendar global styles */
.Calendar {
  & .Calendar__weekDays abbr[title] {
    text-decoration: none;
  }
  & .Calendar__day.-selected,
  & .Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText,
  & .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
    background: #ffd200;
    color: #000;
  }
}

// JivoSite styles
.wrap_0b89 {
  z-index: 4 !important;
}

// Cookie-script styles
#cookiescript_injected {
  background-color: #ffd11c !important;
  padding: 40px 160px !important;
  z-index: 9999999999 !important;

  @media screen and (max-width: 1024px) {
    padding: 40px 80px !important;
  }

  #cookiescript_leftpart {
    #cookiescript_description {
      @include fontFamilyCookie();
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;

      #cookiescript_readmore {
        text-decoration: underline;
      }
    }

    #cookiescript_manage_wrap {
      .cookiescript_gear {
        fill: #000000;
      }
    }
  }

  #cookiescript_toppart {
    flex-direction: column !important;
    position: relative;

    #cookiescript_leftpart {
      max-width: 100%;

      #cookiescript_manage_wrap {
        margin: 20px 0 !important;
      }
    }

    #cookiescript_rightpart {
      margin-left: auto;
      position: absolute;
      right: 0;
      top: 65%;
      min-width: 0 !important;

      @media screen and (max-width: 1024px) {
        top: 62%;
      }

      #cookiescript_buttons {
        width: auto !important;
        flex-direction: row !important;

        #cookiescript_save {
          width: 120px;
          height: 40px;
          background: #000000;
          border: none;
          border-radius: 90px;
          color: #ffffff;
          cursor: pointer;
          @include fontFamilyCookie();
          font-style: normal;
          font-weight: normal;
          padding-top: 2px;
        }

        #cookiescript_accept {
          width: 120px;
          height: 40px;
          background: #000000;
          border: 1px solid #000000;
          color: #ffffff;
          border-radius: 90px;
          cursor: pointer;
          text-decoration: none;
          font-style: normal;
          font-weight: normal;
          padding-top: 2px;
          @include fontFamilyCookie();
        }

        #cookiescript_reject {
          width: 120px;
          height: 38px;
          background: transparent;
          border: 1px solid #000000;
          color: #000000;
          border-radius: 90px;
          cursor: pointer;
          text-decoration: none;
          @include fontFamilyCookie();
          font-style: normal;
          font-weight: normal;
          padding-top: 3px;
        }
      }
    }
  }

  #cookiescript_bottompart {
    #cookiescript_cookietablewrap {
      #cookiescript_maintabs {
        background-color: #000000 !important;
        border-bottom: 1px solid #313131;

        #cookiescript_declaration {
          border-right: 1px solid #313131;
        }

        #cookiescript_aboutcookies {
          border-left: 1px solid #313131;
        }

        .cookiescript_active {
          background-color: #000000 !important;
          color: #ffd11c !important;
          font-weight: 700;
        }
      }

      #cookiescript_tabscontent {
        #cookiescript_declarationwrap {
          background-color: #000000 !important;
          color: #ffffff !important;

          #cookiescript_categories {
            border-bottom: none;

            div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 120px;
              height: 25px;
              background: transparent;
              border: 1px solid #ffd11c;
              border-radius: 90px;
              cursor: pointer;
              text-decoration: none;
              color: #ffffff !important;
              @include fontFamilyCookie();
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 16px;

              &.cookiescript_active {
                background: #ffd11c;
                color: #000000 !important;
              }
            }
          }

          #cookiescript_reportwrap {
            @include fontFamilyCookie();
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 15px;

            div {
              color: #ffffff !important;
              font-size: 13px !important;
              line-height: 20px !important;
            }

            table {
              thead {
                tr {
                  th {
                    color: #ffffff !important;
                    background: #000000;
                    border-bottom: 1px solid #313131;
                    font-size: 12px !important;
                    line-height: 14px !important;
                  }
                }
              }
              tbody {
                tr {
                  td {
                    color: #ffffff !important;
                    border-bottom: 1px solid #313131;
                    font-size: 12px !important;
                    line-height: 14px !important;
                  }
                }
              }
            }
          }
        }

        #cookiescript_aboutwrap {
          background-color: #000000 !important;
          color: #ffffff !important;
        }
      }
    }
  }

  span {
    color: #000000 !important;
    @include fontFamilyCookie();
    font-style: normal;
    font-weight: normal;
    font-size: 0.95em;
    line-height: 1.4em;

    @media screen and (max-width: 1024px) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  a {
    color: #000000 !important;
    @include fontFamilyCookie();
    font-style: normal;
    font-weight: normal;
    font-size: 0.95em;
    line-height: 1.4em;

    @media screen and (max-width: 1024px) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  #cookiescript_reportdate {
    color: #000000 !important;
    font-size: 5px;
  }

  #cookiescript_copyright {
    font-size: 7px;

    @media screen and (max-width: 1024px) {
      a {
        font-size: 7px !important;
      }
    }
  }
}
