@import '../../assets/styles/variables';

.swap-partner {
  padding: 30px 24px;
  border: 2px solid #f4f4f5;
  border-radius: 8px;
  position: relative;
  background: #f4f4f5;
  display: block;
  &__avatar {
    display: block;
    width: 160px;
    height: 160px;
    margin: 0 auto;
    position: relative;
  }
  &__partner {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 65px;
    height: 25px;
    border: 2px solid #fff;
    background: $primaryYellow;
    border-radius: 130px;
    z-index: 1;
    color: $primaryBlack;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__name {
    margin-top: 20px;
  }
  &__company {
    margin-top: 4px;
    color: $primaryGrey;
  }
  &__themes {
    margin-top: 5px;
  }
  &__theme {
    position: relative;
    padding-left: 15px;
    &::after {
      content: '';
      width: 6px;
      height: 6px;
      background: #ffd200;
      position: absolute;
      border-radius: 50%;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
    }
  }

  & .p-checkbox {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 74px;
    height: 74px;
    &__checkmark {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__selected {
    display: flex;
    align-items: center;
    &-icon {
      width: 14px;
      height: 11px;
      position: relative;
      margin-right: 6px;
      & svg {
        position: absolute;
        &:nth-child(1) {
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        &:nth-child(2) {
          bottom: 0;
          left: 0;
        }
        &:nth-child(3) {
          bottom: 0;
          right: 0;
        }
      }
    }
    margin-top: 10px;
  }
}

.swap-partner.white {
  background: #fff;
}

.p-checkbox {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background-color: #ebeaec;
    border-radius: 6px;
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 12px;
      top: -4px;
      width: 9px;
      height: 23px;
      border: solid #000;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  &:hover &__input ~ &__checkmark {
    opacity: 0.7;
  }
  &__input:checked ~ &__checkmark {
    background-color: $primaryYellow;
  }
  &__input:checked ~ &__checkmark:after {
    display: block;
  }
}
