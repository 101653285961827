.country-select-field {
  padding-top: 10px;
  &__inner {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }
  &__select {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    color: #000000;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 5px 21px 5px 0;
    border-bottom: 1px solid #d8d6da;
    &:focus {
      border-bottom: 2px solid #000;
    }
  }
  &__option {
  }
  &__label {
    font-size: 16px;
    line-height: 24px;
    color: #9e98a3;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    pointer-events: none;
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  &__arrow-icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    pointer-events: none;
    &:hover path {
      fill: #cdcacf;
      -webkit-transition: fill 0.5s ease;
      -o-transition: fill 0.5s ease;
      transition: fill 0.5s ease;
    }
  }

  &__select ~ &__label,
  &__select:focus ~ &__label,
  &__select-filled ~ &__label {
    font-size: 12px;
    line-height: 16px;
    color: #9e98a3;
    top: -4px;
    left: 0;
  }

  &_error {
    .country-select-field {
      &__select {
        border-color: #f93c10;
      }
    }
  }

  &__error {
    color: #f93c10;
    font-size: 14px;
    margin-top: 5px;
    display: none;
    &_visible {
      display: block;
    }
  }
}
