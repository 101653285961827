@import '../../assets/styles/variables';

.fr-summary {
  display: grid;
  align-items: center;
  grid-column-gap: 20px;
  grid-template-columns: 200px minmax(0, 1fr);
  background: #fff;
  border: 2px solid $primaryBlack;
  border-radius: 8px;
  padding: 30px;
  &.edit {
    grid-template-columns: 200px minmax(0, 1fr) 99px;
  }
  &__title {
    color: $primaryGrey;
  }
  &__value {
    color: #000;
  }
  &__edit {
    width: 99px;
    height: 32px;
    padding: unset;
  }
  &__loan {
    opacity: 0; // Hide loan offering
  }
}

.fr-result {
  background: #fff;
  border: 2px solid $primaryBlack;
  border-radius: 8px;
  padding: 30px;
  margin-top: 20px;
  &__top {
    display: grid;
    align-items: center;
    grid-column-gap: 20px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  &__title {
    color: $primaryGrey;
  }
  &__value {
    color: #000;
  }
  &__cash,
  &__fee {
    position: relative;
    margin-left: 15px;
    &::after {
      color: #ffd200;
      opacity: 0.4;
      position: absolute;
      bottom: 0;
      left: -25px;
      font-size: 26px;
      line-height: 30px;
    }
  }
  &__cash::after {
    content: '=';
  }
  &__fee::after {
    content: '-';
  }
}

.fr-summary.black {
  background: #000;
}
.fr-summary.black .fr-summary__title {
  color: #fff;
  opacity: 0.7;
}
.fr-summary.black .fr-summary__value {
  color: #fff;
}
.fr-result.black {
  background: #000;
}
.fr-result.black .fr-result__title {
  color: #fff;
  opacity: 0.7;
}
.fr-result.black .fr-result__value {
  color: $primaryYellow;
}
