@import '../../../assets/styles/variables';
$size: 125px;

.profile-avatar-form {
  position: relative;
  padding: 35px 50px;

  &__content {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__avatar {
    margin: 0 auto;
    & .avatar__initials {
      font-size: 42px;
    }
  }

  &__input {
    max-width: 0;
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
    position: absolute;
    z-index: -30;
  }

  &__controls {
    margin-top: 30px;
  }

  &__upload {
    display: block;
    box-sizing: border-box;
    border-radius: 70px;
    border: 2px solid #ffd200;
    padding: 10px 22px;
    outline: none;
    background: transparent;
    cursor: pointer;
    color: #000000;
    text-align: center;
    background: $primaryYellow;
  }
  &__remove {
    margin-top: 10px;
  }
  &__save {
    margin-top: 40px;
  }
}
