@import "../../../assets/styles/variables";

.statement {
  &_wrap {
    padding-top: 68px;
    padding-bottom: 100px;
  }
  &_container {
    display: flex;
    justify-content: flex-start;
    gap: 56px;
    margin-top: 30px;
    max-width: 832px;
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow: hidden;
    padding-top: 65px;
  }

  &_form {
    flex-grow: 1;
    min-width: 476px;
    max-width: 476px;
  }

  &_group {
    &_date {
      color: $primaryGrey;
    }
  }

  &_list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    margin-top: 8px;
  }

  &_file {
    min-height: 44px;
    background: #000000;
    border-radius: 40px;
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    &_link {
      color: #ffffff;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &_delete {
      & svg {
        min-width: 12px;
      }
    }
  }

  &_buttons {
    display: flex;
    justify-content: flex-end;
  }

  &_btn_more {
    padding: 6px 22px;
    width: 135px;
  }
}

.field {
  width: 100%;
}