@import '../../assets/styles/variables';

.dash-deposit-info {
  padding: 30px;
  border: 2px solid $primaryBlack;
  border-radius: 8px;
  overflow: hidden;
  min-height: 212px;
  display: block;
  &__security {
    font-size: 40px!important;
  }
  &__block {
    & p:first-child {
      color: $primaryGrey;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
