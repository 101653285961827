#modals {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: none;
  background: rgba(0, 0, 0, 0.5);
  &.active {
    display: block;
  }
}

.modals-wrap {
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04),
    0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  overflow: hidden;
  min-width: 150px;
  max-width: 100vw;
  max-height: 100vh;
  &__inner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: stretch;

    transform: scale(0);
    animation-fill-mode: forwards;
    animation-name: appear;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    & > div {
      width: 100%;
      height: 100%;
      position: relative;
      background: #fff;
    }
  }
  &__content {
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
  }

  &__close {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 1;
  }
}

@keyframes appear {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
