@import "../../../../assets/styles/variables";

$gridTemplateColumn: minmax(50px, 50px) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
$gridGap: 30px;

.table {
  min-width: 890px;
  &__wrap {
    margin: 0 -20px;
  }
  &__inner {
    padding: 0 20px;
    width: 100%;
    overflow: auto;
  }
  &__thead,
  &__tbody_row {
    display: grid;
    grid-template-columns: $gridTemplateColumn;
    grid-column-gap: $gridGap;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  &__thead {
    align-items: center;
    &_col {
      padding: 20px 0;
      font-size: 14px;
      line-height: 20px;
      color: #9E98A3;
    }
  }
  &__tbody {
    &_row {
      border-bottom: 1px solid #E6E5E7;
      padding: 22px 0;
      align-items: flex-start;
    }
  }

  &__menu {
    display: none;
    margin-top: 10px;
    &.active {
      display: block;
    }
  }
  &__tooltip {
    width: 100vw;
    max-width: 320px;
    padding: 15px;
    white-space: unset!important;
  }
}

.fundraising:not(:last-child) {
  border-bottom: 1px dashed $primaryGrey;
}

.sources {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.source {
  & div:first-child {
    color: $primaryGrey;
  }
}

.dropdown {
  max-height: 0;
  overflow: hidden;
  &:global.active {
    max-height: 10000000vh;
    transition: max-height 0.3s ease-in-out;
  }
  &_content {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 10px;
}