@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.terms-of-use-page {
  display: flex;
  justify-content: stretch;
  min-height: 100vh;
  &__menu {
    width: 300px;
    background: $primaryBlack;
  }
  &__logo {
    margin-top: 42px;
    margin-left: 64px;
    display: inline-block;
  }
  &__content {
    flex-grow: 1;
    padding: 40px 0 40px;
  }
  &__title {
  }
  &__subtitle {
    margin-top: 30px;
  }
  &__list {
    margin-left: 15px;
    &:not(:first-child) {
      margin-top: 15px;
    }
  }
  &__item,
  &__subitem {
    position: relative;
    padding-left: 45px;
    &::after {
      content: attr(data-index);
      position: absolute;
      left: 0;
      top: 0;
    }
    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  &__sublist {
    margin-top: 10px;
  }
  &__subitem {
    padding-left: 50px;
  }
}
