@import '../../../assets/styles/variables';

.login-form {
  &__title {
    font-size: 34px;
    line-height: 40px;
    color: #000000;
    margin: 10px 0 0 0;
  }
  &__social {
    margin-top: 24px;
  }

  &__email,
  &__submit {
    margin-top: 32px;
  }

  &__password {
    margin-top: 30px;
  }
  &__error {
    margin-top: 20px;
    color: $errorRed;
  }
  &__bottom {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & span:first-child {
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }

  &__link {
    margin-top: 20px;
    display: block;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    color: $primaryGrey;
  }
}
