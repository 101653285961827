.tooltip_trigger {
  width: 20px;
  height: 20px;
  overflow: hidden;
}

.tooltip_content {
  background: #FFFFFF;
  box-shadow: 0 16px 28px rgba(0, 0, 0, 0.08), 0 0 8px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  padding: 10px;
  z-index: 9999;
}
