.progress-bar {
  height: 32px;
  background: #ffd200;
  border-radius: 8px;
  position: relative;
  color: #000;
  &__container {
    width: 100%;
  }
  &__completed {
    background: #ebeaec;
  }
  &__risky {
    background: #f93c10;
    color: #fff;
  }
  &__value {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
}
