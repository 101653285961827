.recover-password_form {
  &__title {
    margin: 0;
    font-size: 34px;
    line-height: 40px;
  }

  &__password,
  &__submit {
    margin-top: 30px;
  }
}
