@import '../../styles/variables';

.scr-circle-list {
  & li {
    position: relative;
    padding-left: 15px;
    &::before {
      content: '';
      width: 6px;
      height: 6px;
      background: $primaryYellow;
      position: absolute;
      border-radius: 50%;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
    }
  }
}

.scr_tree_view {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 10px;
  width: 100%;
  & > div:first-child {
    text-align: right;
  }
  & > div:last-child {
    text-align: left;
    color: $primaryGrey;
  }
  & > div {
    white-space: nowrap;
    flex-grow: 1;
  }
}
