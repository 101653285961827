.top-header {
  margin-top: 40px;
  color: #9e98a3;

  &__inner,
  &__summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__summary {
    flex-grow: 1;
    justify-content: flex-end;
  }
  &__payment,
  &__cash {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    & span.black {
      color: #000;
      margin-left: 5px;
    }

    &_popup {
      background: #FFFFFF;
      position: absolute;
      border-radius: 8px;
      padding: 30px;
      box-shadow: 0 16px 28px rgba(0, 0, 0, 0.08), 0 0 8px rgba(0, 0, 0, 0.06);
      top: calc(100% + 15px);
      right: 0;
      z-index: 20;
      &_triangle {
        position: absolute;
        width: 14px;
        height: 28px;
        z-index: 999;
        top: -21px;
        right: 30px;
        transform: rotate(90deg);
      }
    }
  }
  &__payment {
    margin-right: 20px;
  }
  &__warning {
    width: 300px;
    color: #000;
  }
}
