@import '../../../assets/styles/variables';

.contact-info-form {
  box-sizing: border-box;
  &__title {
    font-size: 34px;
    line-height: 40px;
    margin: 7px 0 0 0;
  }
  &__row {
    margin-top: 27px;
  }

  &__button {
    margin-top: 33px;
  }
  &__link {
    width: 100%;
    margin-top: 24px;
  }
  &__about-intro {
    font-size: 12px;
    line-height: 16px;
    color: $primaryGrey;
  }
}
