.header {
  box-sizing: border-box;
  padding: 28px 0;
  & a {
    text-decoration: none;
    color: #000000;
    &:first-child {
      height: 16px;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__link {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-right: 10px;
    &::after {
      z-index: -1;
      content: '';
      background-color: #ffd200;
      position: absolute;
      right: -9px;
      top: -7px;
      width: 32px;
      height: 32px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      transition: all 0.25s ease-in-out;
    }
    &:hover {
      &::after {
        transform: scale(1.2);
      }
    }
  }
}

.user-menu {
  position: relative;
  cursor: pointer;
  &:hover {
    &::after {
      opacity: 1;
      transition: opacity 0.2s ease;
    }
    .user-menu__logout-button {
      opacity: 1;
      transition: opacity 0.2s ease;
    }
  }
  &::after {
    content: attr(data-email);
    position: absolute;
    opacity: 1;
    right: 100%;
    top: 0;
    padding-right: 10px;
  }
  &__logout-button {
    position: absolute;
    opacity: 1;
    right: 100%;
    bottom: 0;
    color: #000000;
    white-space: nowrap;
    padding-right: 10px;
    &:hover {
      color: #800020;
      transition: color 0.2s ease;
    }
  }
}
