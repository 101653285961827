@import '../../../assets/styles/variables';

.fundraising-settings {
  &__notifications {
    margin-top: 20px;
  }
  &__participate {
    margin-top: 60px;
  }
  &__dashboard {
    margin-top: 40px;
  }
  &__co-founders {
    margin-top: 50px;
    border-bottom: 1px solid #9e98a325;
    &-desc {
      margin: 5px 0 15px;
    }
  }
  &__co-founder {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(270px, 1fr);
    align-items: center;
    grid-column-gap: 20px;
    padding: 15px 0;
    border-top: 1px solid #9e98a325;
    &-avatar {
      margin-right: 12px;
    }
    &-status {
      position: relative;
      color: $primaryGrey;
      padding-left: 24px;
      &.active {
        color: $primaryBlack;
        &::before {
          background: $primaryYellow;
          opacity: 1;
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 16px;
        height: 16px;
        transform: translateY(-50%);
        background: $primaryGrey;
        opacity: 0.2;
        border-radius: 50%;
      }
    }
    &-content {
      overflow: hidden;
      & > div:last-child {
        color: $primaryGrey;
      }
    }
    &-left {
      flex-grow: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  &__reset {
    background: #f4f4f5;
    border-radius: 8px;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.cofounder {
      margin-top: 50px;
    }
    &.founder {
      margin-top: 80px;
    }
    & button {
      display: inline-block;
      width: 236px;
      height: 40px;
      border: 2px solid #000000;
      box-sizing: border-box;
      border-radius: 100px;
      color: #000;
      margin-left: 20px;
    }
    & p {
      margin-top: 5px;
      color: #9e98a3;
    }
  }
}
