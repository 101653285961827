.country {
  min-height: 32px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &_flag {
    width: 24px;
    min-width: 24px;
    height: 17px;
    overflow: hidden;
    border-radius: 2px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &_name {}
  &_selected {
    background: #F3F2F4;
  }
}