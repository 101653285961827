@import "../../assets/styles/variables";

.wrapper {
  padding: 25px;
  width: calc(100vw - 40px);
  max-width: 472px;
  padding-top: 55px;
}

.title {}

.desc {
  margin-top: 20px;
  color: $primaryGrey;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 40px;
}

.button {
  &_delete {
    background: #D80027;
    border: 2px solid #D80027;
    :global .round-button__label {
      color: #FFFFFF;
    }
  }

  &_confirm {}

  &_cancel {
    background: #F3F2F4;
    border: 2px solid #F3F2F4;
    :global .round-button__label {
      color: $primaryGrey;
    }
  }
}