.change-pass-form {
  padding: 40px 70px;
  &__title {
    margin-bottom: 50px;
  }
  &__subtitle {
    margin-bottom: 20px;
  }
  &__buttons,
  &__forgot {
    display: flex;
    justify-content: flex-end;
  }
  &__forgot {
    margin-top: 10px;
    & button {
      color: #9e98a3;
    }
  }
  &__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    & button {
      &:not(:last-child) {
        margin-right: 10px;
      }
      width: 150px;
    }
  }
}
