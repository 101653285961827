.profile-personal-form {
  box-sizing: border-box;
  padding: 40px 70px;
  width: 50vw;
  &__title {
    margin: 7px 0 0 0;
  }
  &__subtitle {
    margin-top: 50px;
  }
  &__subtitle + &__row {
    margin-top: -15px;
  }
  &__age {
    margin-top: 27px;
  }
  &__link {
    width: 100%;
    margin-top: 24px;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 30px;
  }
  &__field {
    margin-top: 27px;
  }
  &__group {
    margin-top: 27px;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  &__button {
    max-width: 150px;
    margin-top: 33px;
    &:first-child {
      margin-right: 15px;
    }
  }
}
