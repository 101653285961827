.wrapper {
  background: #FFFFFF;
  width: 100vw;
  max-width: 688px;
  border-radius: 16px;
  box-shadow: 0 3px 10px 0 rgba(21, 21, 21, 0.05);
  padding: 32px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  h1 {
    padding-right: 60px;
  }
}
.disclaimer {}
.checkboxes {}
.buttons {}
.submit {}