@import "../../assets/styles/variables";

.warning_block {
  display: flex;
  align-items: flex-start;
  background: #9E98A310;
  border-radius: 8px;
  padding: 16px 20px;
  gap: 8px;

  &__icon {
    padding-top: 2px;
    img {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }
  }

  &__content {
    flex-grow: 1;
  }

}