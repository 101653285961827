.country_select {
  & :global .scr_select__menu-list {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &_error :global .scr_select__control {
    border-bottom: 1px solid #F93C10!important;
  }
}

.error_message {
  color: #F93C10;
  font-size: 14px;
  margin-top: 5px;
}