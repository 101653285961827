@import '../../../assets/styles/variables';

.radio-button {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-button input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-button__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #ebeaec;
  border-radius: 50%;
}

.radio-button:hover input ~ .radio-button__checkmark {
  background-color: #ccc;
}

.radio-button input:checked ~ .radio-button__checkmark {
  background-color: $primaryYellow;
}

.radio-button input:disabled ~ .radio-button__checkmark {
  background-color: #ccc;
}

.radio-button__checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radio-button input:checked ~ .radio-button__checkmark:after {
  display: block;
}

.radio-button .radio-button__checkmark:after {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #000;
}
