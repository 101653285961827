@import '../../../assets/styles/variables';

.cofounder_fr_accept_form {
  &__submit {
    margin-top: 30px;
  }
  &__warning {
    margin-bottom: 10px;
    justify-content: center;
  }
  &__checkbox {
    color: $primaryGrey;
    & a {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
