.wrapper {
  max-width: 440px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.inner {
  border: 2px solid #eceaed;
  border-top: none;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 20px 20px;
  padding: 0 20px;
  padding-bottom: 25px;
  flex-grow: 1;
}

.top {
  flex-grow: 1;
}

.image {
  font-size: 0;
  height: 240px;
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.review {
  background: #ffffff;
  border-radius: 10px;
  padding: 0 14px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
  position: absolute;
  top: 16px;
  right: 16px;
}

.title {
  color: #000000;
}

.desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #45444a;
  margin: 0 !important;
  margin-top: 8px;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #45444a;
  margin-top: 20px;
}

.country {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #9e98a3;
    object-fit: cover;
    overflow: hidden;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  margin: 20px auto 0;
  padding: 5px 22px;
  &:global.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 32px;
}

.header_left {
}
.header_right {
  text-align: right;
  span {
    color: #000000;
    position: relative;
    z-index: 0;
    &::before {
      content: '';
      width: 32px;
      height: 32px;
      background: #ffd200;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -16px;
      z-index: -1;
    }
  }
}

.description {
  color: #000000;
  margin-top: 20px;
}

.more {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  text-align: center;
}

.foundation {
}

.link {
  width: 268px;
  height: 46px;
  margin: 8px auto 0;
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: #000000;
  }
}
.bottom {
}
