@import '../../../../assets/styles/variables';

.bank_transfer {
  &__title {
  }
  &__desc {
    margin-top: 30px;
  }
  &__total {
    margin-top: 35px;
    &_label {
      color: $primaryGrey;
    }
    &_amount {
    }
  }
  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 60px;
    margin-top: 45px;
  }
  &__req {
    min-width: 440px;
  }
  &__req_desc {
    color: $primaryGrey;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 900px) {
  .bank_transfer {
    &__req {
      min-width: unset;
      max-width: unset;
      width: 100%;
    }

    &__container {
      flex-direction: column;
    }
  }
}
