@import '../../../assets/styles/variables';

.wrapper {
}

.title {
  font-size: 34px;
  line-height: 40px;
  margin: 7px 0 0 0;
}

.desc {
  margin-top: 25px;
}

.input,
.buttons {
  margin-top: 45px;
}

.error_input {
  & input {
  }
}
.error_message {
  margin-top: 10px;
  color: $errorRed;
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  &_row {
    flex-direction: row;
  }
}

.button {
  &_back {
    max-width: 150px;
  }
  &_submit {
    max-width: 352px;
  }
  &_resend {
  }
  font-weight: 500;
  &_later {
    display: inline-block;
    color: $primaryGrey;
  }
}
