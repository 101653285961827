@import '../../assets/styles/variables';

.profile-page {
  padding: 50px 0;
  &__preloader {
    height: 40vh;
  }
  &__veriff {
    &-section {
      margin-top: 50px;
      padding-top: 50px;
    }
    &-company {
      display: flex;
      align-items: center;
      margin-top: 10px;
      & svg {
        margin-right: 10px;
      }
    }
    &-verified {
      color: #9e98a3;
      margin-top: 15px;
      & p:first-child {
        color: $primaryBlack;
      }
    }
  }
  &__password {
    margin-top: 40px;
    &-btn {
      margin-top: 20px;
    }
  }

  & .veriff-support {
    display: flex;
    align-items: center;
    & a {
      margin-left: 5px;
    }
  }
}
