@import '../../../assets/styles/variables';

.copy_button {
  background: transparent;
  width: 24px;
  height: 24px;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover ~ .hidable {
    display: none;
  }
  & svg:hover {
    & path {
      fill: $primaryYellow;
    }
  }
  &.copy {
    & span {
      display: none;
    }
  }
  &.copied {
    & svg path {
      fill: $primaryYellow;
    }
    & span {
      background: $primaryYellow;
    }
  }
  &:hover {
    & span {
      display: block;
    }
  }
  & span {
    position: absolute;
    line-height: 18px;
    top: 50%;
    transform: translateY(-50%);
    right: calc(100% + 10px);
    background: $primaryWhite;
    border: 1px solid $primaryYellow;
    border-radius: 130px;
    padding: 0 10px 2px 10px;
    color: $primaryBlack;
  }
}
