@import '../../../assets/styles/variables';

.statement_upload {
  &__label {
    display: flex;
    border: 1px solid #D8D6DA;
    border-radius: 6px;
    padding: 10px;
    transition: 0.2s ease-in-out;
    min-height: 70px;
    &:hover {
      cursor: pointer;
      border-color: $primaryBlack;
      transition: 0.2s ease-in-out;
    }
    &:hover .statement_upload__centered {
      color: $primaryBlack;
      transition: 0.2s ease-in-out;
    }
    &_error {
      border-color: $errorRed;
    }
    &_error .statement_upload__centered {
      color: $errorRed;
    }
  }

  &__input {
    opacity: 0;
    max-width: 0;
    max-height: 0;
    position: absolute;
    z-index: -1000000;
  }

  &__centered {
    margin: auto;
    color: $primaryGrey;
    transition: 0.2s ease-in-out;
    text-align: center;
    &_desc {
      margin-top: 5px;
    }
  }

  &__title {
    color: $primaryGrey;
  }
}
