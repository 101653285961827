@mixin hideScroll {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

@mixin modalMinSize {
  min-width: 880px;
  max-width: 100vw;
  padding: 50px;
}

@mixin clampText($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
