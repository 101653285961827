@import '../../../assets/styles/variables';

.founder-card {
  padding: 30px 24px;
  border: 2px solid #ebeaec;
  border-radius: 8px;
  position: relative;
  display: block;
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &__top {
    flex-grow: 1;
  }
  &__avatar {
    display: block;
    width: 160px;
    height: 160px;
    margin: 0 auto;
    position: relative;
  }
  &__received {
    position: absolute;
    bottom: -13px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 120px;
    background: $primaryYellow;
    border: 2px solid #ffffff;
    border-radius: 130px;
    padding: 4px 10px;
    max-width: 100%;
    & span:first-child {
      color: #000000;
      opacity: 0.4;
    }
  }
  &__name {
    margin-top: 20px;
  }
  &__company {
    color: #9e98a3;
  }
  &__themes {
    margin-top: 5px;
  }
  &__theme {
    position: relative;
    padding-left: 15px;
    &::after {
      content: '';
      width: 6px;
      height: 6px;
      background: #ffd200;
      position: absolute;
      border-radius: 50%;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
    }
  }
  &__middle {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  &__goal {
    margin-right: 4px;
  }
  &__goal,
  &__loans {
    background: #ebeaec;
    border: 1px solid #ffffff;
    border-radius: 130px;
    padding: 2px 12px;
    & span:first-child {
      color: #9e98a3;
    }
  }
  &__select {
    margin-top: 16px;
  }
}
