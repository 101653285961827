@import '../../assets/styles/variables';

.statement_upload {
  &__label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primaryGrey;
    border-radius: 15px;
    padding: 30px 50px;
    transition: 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
      border-color: black;
      transition: 0.2s ease-in-out;
    }
    &:hover .statement_upload__centered {
      color: black;
      transition: 0.2s ease-in-out;
    }
    &_error {
      border-color: $errorRed;
    }
    &_error .statement_upload__centered {
      color: $errorRed;
    }
  }

  &__input {
    opacity: 0;
    max-width: 0;
    max-height: 0;
    position: absolute;
    z-index: -1000000;
  }

  &__centered {
    color: $primaryGrey;
    transition: 0.2s ease-in-out;
    text-align: center;
    &_desc {
      margin-top: 5px;
    }
  }

  &__title {
    color: $primaryGrey;
  }
}
