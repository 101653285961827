@import '../../assets/styles/variables';

$navigationHeight: 60px;
.tablet-navigation {
  &__wrap {
    min-height: $navigationHeight;
    max-height: $navigationHeight;
    background: #000;
    display: flex;
    align-items: center;
    width: 100%;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__links {
    display: flex;
    flex-wrap: nowrap;
  }
  &__link {
    color: #fff;
    margin: 0 15px;
    &-active {
      color: #ffd200;
    }
  }
  &__user {
    &-avatar {
      width: 32px;
      height: 32px;
      min-width: 32px;
      max-width: 32px;
      margin-right: 12px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid #fff;
      &.active {
        border: 2px solid $primaryYellow;
      }
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-icon {
      min-width: 32px;
      margin-right: 12px;
    }
  }
}
