@import '../../../assets/styles/variables';

.profile-startup-form {
  box-sizing: border-box;
  padding: 40px 70px;
  max-width: 900px;
  width: 100vw;

  @media screen and (max-width: 580px) {
    padding: 15px;
  }

  &__group {
    margin-top: 30px;
  }
  &__company_registry {
    max-width: 308px;
  }

  &__promo,
  &__logo {
    margin-top: 30px;
    &-title {
      color: $primaryGrey;
    }
    &-remove {
      margin-left: 15px;
      color: $errorRed;
    }
    &-upload {
      &.button {
        border-radius: 70px;
        display: inline-block;
        padding: 5px 15px;
        border: 2px solid $primaryYellow;
      }
      cursor: pointer;
      margin-top: 5px;
      & input {
        display: none;
        opacity: 0;
        position: absolute;
        left: -100vw;
        width: 0;
        height: 0;
      }
    }
    &-preview {
      margin-top: 10px;
      & img {
        max-width: 100%;
        height: 100%;
        max-height: 70px;
        -o-object-fit: contain;
        object-fit: contain;
      }
    }
  }
  &__promo {
    &-preview {
      & img {
        max-width: 100%;
        height: unset;
        max-height: unset;
      }
    }
  }
  &__title {
    margin: 7px 0 0 0;
  }

  &__subtitle {
    margin-top: 50px;
  }
  &__subtitle + &__row {
    margin-top: -15px;
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 30px;
    align-items: flex-end;
  }

  &__field {
    margin-top: 27px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  &__button {
    max-width: 150px;
    margin-top: 33px;
    &:first-child {
      margin-right: 15px;
    }
  }
  &__link {
    width: 100%;
    margin-top: 24px;
  }
}
