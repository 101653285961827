.tooltip_trigger {
  width: 20px;
  height: 20px;
  overflow: hidden;
}

.tooltip_content {
  background: #FFFFFF;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 10px;
}
