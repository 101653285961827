.account-select {
  border: 2px solid #cbc8cd;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px 25px 13px 20px;
  position: relative;
  cursor: pointer;
  min-width: 300px;
  &__error {
    border-color: #f93c10;
  }
  &__left {
    & p:first-child {
      color: #000;
    }
  }
  &__right {
    transform: rotate(180deg) scale(1.2);
    transition: transform 0.3s;
  }

  &__active {
    .account-select__menu {
      display: block;
    }

    .account-select__right {
      transform: rotate(0);
      transition: transform 0.3s;
    }
  }

  &__menu {
    width: 101%;
    top: 110%;
    left: -2px;
    display: none;
    position: absolute;
    background: #ffffff;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    z-index: 1;
    &-empty {
      padding: 20px;
    }
  }
  &__option {
    padding: 20px;
    color: #000;
    &:not(:last-child) {
      border-bottom: 1px solid #e0e0e0;
    }
  }
}
