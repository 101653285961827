@import '../../../assets/styles/variables';

.form {
}
.title {
}
.subtitle {
}
.bonus {
  margin-top: 20px;
}
.star_icon {
  margin-bottom: 10px;
}
.amount {
  margin-top: 50px;
  &_container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    margin-top: 10px;
  }
  &_right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 10px;
  }
  &_field {
    max-width: 210px;
  }
  &_presets {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding-top: 20px;
  }
  &_preset {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: #ffffff;
    border: 2px solid #ebeaec;
    border-radius: 8px;
    min-height: 57px;
    min-width: 85px;
    cursor: pointer;
    &:global.selected {
      background: rgba(255, 210, 0, 0.04);
      border: 2px solid #ffd11c;
      border-radius: 8px;
    }
    &:global.disabled {
      background: unset !important;
      border: 2px solid #ebeaec !important;
      border-radius: 8px;
    }
    &:hover {
      background: rgba(255, 210, 0, 0.04);
      border: 2px solid #ffd11c;
      border-radius: 8px;
    }
  }
}

.method {
  margin-top: 50px;
  &_title {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 35px;
  }
  &_commission_card {
    color: $primaryGrey;
    :global .scr-circle-list {
      margin-left: 18px;
      padding: 10px 0;
    }
    :global .scr-circle-list li::before {
      background: $primaryGrey;
    }
  }
  &_item {
    border: 2px solid #f4f4f5;
    border-radius: 8px;
    padding: 30px 20px 30px 65px !important;
    &_commission {
      margin: 8px 0 6px;
    }
    &_desc {
      color: $primaryGrey;
    }

    &:global.selected {
      background: rgba(255, 210, 0, 0.04);
      border: 2px solid #ffd11c;
    }

    &:global.disabled {
      background: unset !important;
      border: 2px solid #f4f4f5 !important;
    }

    &:hover {
      background: rgba(255, 210, 0, 0.04);
      border: 2px solid #ffd11c;
    }

    & :global .radio-button__checkmark {
      top: 30px !important;
      left: 24px !important;
    }
  }
}

.total {
  margin-top: 35px;
  &_label {
    color: $primaryGrey;
  }
  &_amount {
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-top: 35px;
}

.buttons_desc {
  display: grid;
  grid-template-columns: 132px 1fr;
  margin-top: 12px;
  &:global.line {
    grid-template-columns: 1fr;
  }
  & div:last-child {
    color: $primaryGrey;
    text-align: center;
  }
}

.description {
  color: $primaryGrey;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 65px;
}

.button {
  &_back {
    min-width: 132px;
  }
  &_submit {
  }
}

@media screen and (max-width: 720px) {
  .amount {
    &_container {
      flex-direction: column;
      gap: 20px;
    }

    &_field {
      max-width: 210px;
    }
    &_presets {
      gap: 10px;
    }
    &_preset {
      min-height: 50px;
      min-width: 75px;
    }
  }

  .method {
    &_item {
      //padding: 25px 20px 25px 55px;
    }
  }

  .button {
    &_back {
      min-width: 110px;
    }
  }

  .buttons_desc {
    grid-template-columns: 1fr;
  }

  .description {
    margin-top: 40px;
  }
}
