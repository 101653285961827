@import "../../../assets/styles/variables";

.wrapper {}

.label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: $primaryGrey;
}

.upload {
  margin-top: 20px;
}

.files {
  margin-top: 20px;
}

.tooltip_text {
  max-width: 332px;
}