.wrapper {
  background: #F4F4F5;
  padding: 35px;
  border-radius: 8px;
  margin-top: 24px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan {
  text-align: center;
  margin-top: 8px;
}

.table_standard, .table_advanced {
  width: 100%;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  border: 1px solid #E6E5E7;
  border-collapse: collapse;
  margin-top: 35px;
  table-layout: fixed;
  td {
    padding: 16px;
    border: 1px solid #E6E5E7;
  }
}

.table_standard {
  td:nth-child(2), td:nth-child(3)  {
    background: #FFF4E7;
  }
  td:nth-child(4) {
    color: #9E98A3;
  }
}

.table_advanced {
  td:nth-child(2), td:nth-child(4)  {
    background: #FFF4E7;
  }
  td:nth-child(3), td:nth-child(5) {
    color: #9E98A3;
  }
  div {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      font-size: 32px;
      line-height: 48px;
      color: #F17100;
    }
  }
}