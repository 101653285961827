.wrapper {}

.title {}

.desc {
  margin-top: 30px;
  margin-bottom: 5px;
}

.banner {
  margin-top: 30px;
}

.warnings {
  & :global .warning_block:first-child {
    margin-top: 50px;
  }
  & :global .warning_block:not(:first-child) {
    margin-top: 15px;
  }
}

.message {
  margin-top: 30px;
}

.button {
  margin-top: 30px;
  min-width: 272px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}