@import '../../assets/styles/variables';

.loan-info {
  max-width: 850px;
  padding: 73px;
  display: flex;
  flex-direction: column;
  gap: 70px;
  &__header {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 20px;
    & > div {
      overflow: hidden;
    }
    & > div p:first-child {
      color: $primaryGrey;
    }
  }

  &__empty-message {
    margin-top: 15px;
    color: $primaryGrey;
    & a {
      color: #000;
    }
  }
}

.loan-info-details {
  $gridTemplateColumn_d_success_fee: repeat(5, minmax(0, 1fr));
  $gridTemplateColumn_d_six_fee: repeat(4, minmax(0, 1fr));
  $gridGap_d: 20px;
  $gridGap_d: 20px;

  &__table {
    margin-top: 10px;
  }

  &__thead,
  &__tbody-row {
    display: grid;
    grid-column-gap: $gridGap_d;
    align-items: center;
    justify-content: space-between;
  }

  &__thead.success_fee,
  &__tbody-row.success_fee {
    grid-template-columns: $gridTemplateColumn_d_success_fee;
  }

  &__thead.six_fee,
  &__tbody-row.six_fee {
    grid-template-columns: $gridTemplateColumn_d_six_fee;
  }

  &__thead {
    white-space: nowrap;
    border-bottom: 1px solid #e6e5e7;
    &-col {
      padding: 16px 0;
      font-size: 14px;
      line-height: 20px;
      color: #9e98a3;
    }
  }

  &__tbody {
    &-row {
      border-bottom: 1px solid #e6e5e7;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
    &-col {
      padding: 21px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.loan-info-payments {
  $gridTemplateColumn_p: minmax(110px, 110px) minmax(170px, 170px) 1fr;
  $gridGap_p: 30px;

  &__table {
    margin-top: 5px;
  }

  &__thead,
  &__tbody-row {
    display: grid;
    grid-template-columns: $gridTemplateColumn_p;
    grid-column-gap: $gridGap_p;
    align-items: center;
    justify-content: space-between;
  }

  &__thead {
    border-bottom: 1px solid #e6e5e7;
    &-col {
      padding: 16px 0;
      font-size: 14px;
      line-height: 20px;
      color: #9e98a3;
    }
  }

  &__tbody {
    &-row {
      border-bottom: 1px solid #e6e5e7;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
    &-col {
      padding: 21px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__description {
    text-overflow: unset;
    white-space: unset;
  }
}

.loan-info-insurance {
  $gridTemplateColumn_s: repeat(3, minmax(0, 1fr));
  $gridGap_s: 30px;

  &__table {
    margin-top: 8px;
  }

  &__thead,
  &__tbody-row {
    display: grid;
    grid-template-columns: $gridTemplateColumn_s;
    grid-column-gap: $gridGap_s;
    align-items: center;
    justify-content: space-between;
  }

  &__thead {
    border-bottom: 1px solid #e6e5e7;
    &-col {
      padding: 16px 0;
      font-size: 14px;
      line-height: 20px;
      color: #9e98a3;
    }
  }

  &__tbody {
    &-row {
      border-bottom: 1px solid #e6e5e7;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
    &-col {
      padding: 23px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

$gridGap_p: 20px;

.loan-info-contracts {
  &.custom &__thead,
  &.custom &__tbody-row {
    grid-template-columns: repeat(4, minmax(0, 1fr)) minmax(160px, 160px);
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__table {
    margin-top: 5px;
  }
  &__thead,
  &__tbody-row {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: $gridGap_p;
    align-items: center;
    justify-content: space-between;
  }
  &__thead {
    border-bottom: 1px solid #e6e5e7;
    &-col {
      padding: 16px 0;
      font-size: 14px;
      line-height: 20px;
      color: #9e98a3;
    }
  }
  &__tbody {
    &-row {
      border-bottom: 1px solid #e6e5e7;
    }
    &-col {
      padding: 21px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
