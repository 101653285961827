.info-toolbox-red {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  &__icon {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  &__angle {
    height: 28px;
    width: 14px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -14px;
  }
  &__content {
    &-wrap {
      z-index: 999;
      position: absolute;
      display: none;
      border-radius: 8px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.06), 0 16px 28px rgba(0, 0, 0, 0.08);
      background: #fff;
      top: 50%;
      max-width: 348px;
      transform: translateY(-50%);
      left: 40px;
      &.visible {
        display: block;
      }
      &.bottom {
        top: 40px;
        transform: unset;
        left: unset;
        right: -28px;
        & .info-toolbox-red__angle {
          transform: rotate(90deg);
          left: unset;
          top: unset;
          bottom: 93%;
          right: 30px;
        }
      }
    }
    padding: 30px;
  }
}
