.payments-page {
  margin-top: 50px;
  &__links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__link {
    margin-top: 25px;
    font-size: 20px;
    line-height: 32px;
    color: #9e98a3;
    padding: 8px 0;
    &-selected {
      color: #000;
      border-bottom: 4px solid #ffd200;
    }
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}
